import React, { useState } from 'react';
import { Button, Form, Label, Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import LongListLabel from './LongListLabel';

const WelcomeWidget = () => {
  const [currentScreen, setCurrentScreen] = useState(1);
  const [selectedPaths, setSelectedPaths] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [recommendedCourses, setRecommendedCourses] = useState([]);

  const learningPaths = [
    { key: 'ds', text: 'Data Science', value: 'Data Science' },
    { key: 'se', text: 'Software Engineering', value: 'Software Engineering' },
    { key: 'fd', text: 'Frontend Development', value: 'Frontend Development' },
    { key: 'bd', text: 'Backend Development', value: 'Backend Development' },
    { key: 'da', text: 'Data Analysis', value: 'Data Analysis' },
    { key: 'ex', text: 'Excel', value: 'Excel' },
    { key: 'ml', text: 'Machine Learning', value: 'Machine Learning' },
    { key: 'cs', text: 'Cybersecurity', value: 'Cybersecurity' }
  ];

  const handleSkip = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/welcome/skip`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to skip welcome message');
      }

      window.location.reload();
    } catch (error) {
      console.error('Error skipping welcome message:', error);
      toast.error('Failed to skip. Please try again.');
    }
  };

  const togglePath = (value) => {
    if (selectedPaths.includes(value)) {
      setSelectedPaths(selectedPaths.filter(p => p !== value));
    } else {
      setSelectedPaths([...selectedPaths, value]);
    }
  };

  const fetchRecommendedCourses = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/welcome/recommend-courses`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({
          learningPaths: selectedPaths
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch course recommendations');
      }

      const data = await response.json();
      setRecommendedCourses(data.courses);
    } catch (error) {
      console.error('Error fetching course recommendations:', error);
      toast.error('Failed to load course recommendations. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    window.location.reload();
  };

  const handleNext = async () => {
    await fetchRecommendedCourses();
    setCurrentScreen(2);
  };

  const renderFirstScreen = () => (
    <Form>
      <h2>What do you want to learn?</h2>
      <p>Select the areas you're interested in learning about:</p>
      <Form.Field>
        <div className="ui labels">
          {learningPaths.map((path) => (
            <Label
              key={path.key}
              as="a"
              onClick={() => togglePath(path.value)}
              color={selectedPaths.includes(path.value) ? 'blue' : undefined}
              style={{ marginBottom: '5px', cursor: 'pointer' }}
            >
              {path.text}
            </Label>
          ))}
        </div>
      </Form.Field>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1em' }}>
        <Button
          primary
          onClick={handleNext}
          disabled={selectedPaths.length === 0 || isLoading}
          loading={isLoading}
        >
          Show me recommended courses
        </Button>
        <Button onClick={handleSkip}>
          Skip
        </Button>
      </div>
    </Form>
  );

  const renderSecondScreen = () => {
    // Group courses by learning path
    const coursesByPath = recommendedCourses.reduce((acc, course) => {
      if (!acc[course.learning_path]) {
        acc[course.learning_path] = [];
      }
      acc[course.learning_path].push(course);
      return acc;
    }, {});

    return (
      <Form>
        <h4>Here are some basic courses to get you started!</h4>
        {Object.entries(coursesByPath).map(([learningPath, courses]) => (
          <div key={learningPath} className="ui segment">
            <Label color='teal' size='big' style={{ marginBottom: '10px' }}>{learningPath}</Label>
            <div>
              {courses.map((course, index) => (
                <div key={index} className="ui segment">
                  <h4>{course.title}</h4>
                  
                  {/* Topics section */}
                  {course.skills && course.skills.length > 0 && (
                    <div style={{ marginTop: '10px' }}>
                      <LongListLabel labels={course.skills} color="teal" />
                    </div>
                  )}

                  {course.link && (
                    <Button 
                      as="a" 
                      href={course.link} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      size="small"
                      style={{ marginTop: '10px' }}
                      primary
                    >
                      View Course
                    </Button>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </Form>
    );
  };

  return (
    <Modal open onClose={handleClose}>
      <Modal.Header>Welcome to Your Learning Journey!</Modal.Header>
      <Modal.Content>
        {currentScreen === 1 && renderFirstScreen()}
        {currentScreen === 2 && renderSecondScreen()}
      </Modal.Content>
      {currentScreen === 2 && (
        <Modal.Actions>
          <Button onClick={() => setCurrentScreen(1)}>
            Back
          </Button>
          <Button primary onClick={handleClose}>
            Done
          </Button>
        </Modal.Actions>
      )}
    </Modal>
  );
};

export default WelcomeWidget;