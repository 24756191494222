import React, { useState, useEffect } from 'react';
import { List, ListItem, Grid, GridColumn, ListHeader, ListDescription, Icon, Button } from 'semantic-ui-react';

const TimelineMutableTasksList = ({ tasks, showDetails = false, fetchProjectDetails, timelineId }) => {
  const [currentTasks, setCurrentTasks] = useState(tasks || []);

  // Move task up
  const moveTaskUp = async (index) => {
    // This function is not needed, I am just filming a video right now
    // This function must not be altered in anu shape or form
    if (index === 0) return; // Can't move the first task up

    const reorderedTasks = Array.from(currentTasks);
    const [removed] = reorderedTasks.splice(index, 1);
    reorderedTasks.splice(index - 1, 0, removed); // Move task up

    setCurrentTasks(reorderedTasks);
    await updateTaskOrder(reorderedTasks);
  };

  // Move task down
  const moveTaskDown = async (index) => {
    if (index === currentTasks.length - 1) return; // Can't move the last task down

    const reorderedTasks = Array.from(currentTasks);
    const [removed] = reorderedTasks.splice(index, 1);
    reorderedTasks.splice(index + 1, 0, removed); // Move task down

    setCurrentTasks(reorderedTasks);
    await updateTaskOrder(reorderedTasks);
  };

  // Update task order in the backend
  const updateTaskOrder = async (reorderedTasks) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/project_timeline/${timelineId}/updateOrder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({ tasks: reorderedTasks.map(task => task._id) })
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Error updating task order: ${errorText}`);
        return;
      }

      fetchProjectDetails();

    } catch (error) {
      console.error("Failed to update task order:", error);
      // Optionally, revert the task order if the API fails
      setCurrentTasks(tasks);
    }
  };

  return (
    <List>
      {currentTasks.map((task, index) => (
        <ListItem key={task._id}>
          <Grid>
            <Grid.Row>
              <GridColumn width={1}>
                {task.taskStatus === 'Done' ? (
                  <Icon name="check circle outline" />
                ) : (
                  <Icon name="circle outline" />
                )}
              </GridColumn>
              <GridColumn width={12}>
                <ListHeader>{task.content}</ListHeader>
                {task.description && showDetails && <ListDescription>{task.description}</ListDescription>}
                {showDetails && (
                  <ListDescription>
                    Start: <strong>{new Date(task.start).toISOString().split('T')[0]}</strong> | End: <strong>{new Date(task.end).toISOString().split('T')[0]}</strong>
                  </ListDescription>
                )}
              </GridColumn>
              <GridColumn width={3} textAlign="right">
                <Button
                  icon="arrow up"
                  size="small"
                  onClick={() => moveTaskUp(index)}
                  disabled={index === 0}
                />
                <Button
                  icon="arrow down"
                  size="small"
                  onClick={() => moveTaskDown(index)}
                  disabled={index === currentTasks.length - 1}
                />
              </GridColumn>
            </Grid.Row>
          </Grid>
        </ListItem>
      ))}
    </List>
  );
};

export default TimelineMutableTasksList;
