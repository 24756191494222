import React, { useState } from 'react';
import { Button, Form, Input, Segment, Dropdown, TextArea } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { jobRoleOptions } from '../displayHelpers';

function stripHtmlTagsUsingRegex(htmlString) {
  // Remove HTML tags
  let text = htmlString.replace(/<\/?[^>]+(>|$)/g, "");
  
  // Remove new lines and extra whitespace
  text = text.replace(/\s+/g, " ").trim(); // Replaces multiple spaces with a single space and trims leading/trailing spaces

  return text;
}

const ManualJobEntry = () => {
  const [jobLink, setJobLink] = useState('');
  const [jobRole, setJobRole] = useState('');
  const [jobHTML, setJobHTML] = useState('')
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = JSON.stringify({ jobLink, jobRole, jobHTMLRaw: stripHtmlTagsUsingRegex(jobHTML) });
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/jobs/parseJob`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: payload,
    });

    if (response.ok) {
      toast.success('Scraped job successfully!');
    } else {
      toast.error('Failed to scrape job!');
    }
    setLoading(false);
  };

  return (
    <Segment>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Field>
            <Input
              type="text"
              value={jobLink}
              onChange={(e) => setJobLink(e.target.value)}
              placeholder="Enter LinkedIn job link"
            />
          </Form.Field>
          <Form.Field>
            <Dropdown
              placeholder='Select Job Role'
              fluid
              selection
              options={jobRoleOptions()}
              value={jobRole}
              onChange={(_, { value }) => setJobRole(value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Job HTML</label>
            <TextArea
              placeholder="Job HTML"
              value={jobHTML}
              onChange={(e) => setJobHTML(e.target.value)}
            />
          </Form.Field>
          <Button positive loading={loading} onClick={handleSubmit}>Submit to Queue</Button>
        </Form.Group>
        </Form>
    </Segment>
  );
};

export default ManualJobEntry;
