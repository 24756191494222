import React, { useState, useEffect } from 'react';
import { Button, Segment, Progress, Icon, Grid, Accordion, Label, List } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';

const CollapsibleKeyTerms = ({ keyTerms }) => {
    const [activeIndex, setActiveIndex] = useState(-1);

    const handleClick = () => {
        setActiveIndex(activeIndex === 0 ? -1 : 0);
    };
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '100%' }}>
        <Accordion styled style={{ margin: '20px 0', width: '100%' }}>
            <Accordion.Title active={activeIndex === 0} onClick={handleClick}>
                <Icon name={activeIndex === 0 ? 'caret down' : 'caret right'} />
                <i>Key Terms</i> 
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
                <Segment>
                {keyTerms && Object.keys(keyTerms).length > 0 ? (
                    <List divided selection>
                        {Object.entries(keyTerms).map(([term, definition], index) => (
                            <List.Item key={index}>
                                <Label color="blue" horizontal>
                                    {term}
                                </Label>
                                {definition}
                            </List.Item>
                        ))}
                    </List>
                ) : (
                    <p>`No key terms available.`</p>
                )}
                </Segment>
            </Accordion.Content>
        </Accordion>
        </div>
    );
};

// TODO: this chunking should move to the backend, and may be even chunk it up at the AI generation level
function breakTextIntoChunks(text) {
    if (!text) { return [] }
    // Split the text into paragraphs
    const paragraphs = text.split(/\n\s*\n/);
    
    // Function to split paragraph into sentences
    const splitIntoSentences = (paragraph) => {
        return paragraph.match(/[^.!?]+[.!?]+/g) || []; // Match sentences ending with ., !, or ?
    };

    const chunks = [];

    paragraphs.forEach(paragraph => {
        const sentences = splitIntoSentences(paragraph);
        let chunk = '';

        sentences.forEach((sentence, index) => {
            // Add the sentence to the current chunk
            chunk += sentence.trim() + ' ';
            
            // Check if we have reached 1-3 sentences
            if ((index + 1) % 2 === 0 || index === sentences.length - 1) {
                chunks.push(chunk.trim());
                chunk = ''; // Reset the chunk
            }
        });
    });

    return chunks;
}

// Helper function to create a label
function createLabel(color, content, icon) {
    return <Label color={color} icon={icon} style={{ margin: '5px' }}>{content}</Label>;
}

const LearningComponent = ({ currentTask }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showEasyExplanation, setShowEasyExplanation] = useState(false);
    
    const topicsMaterial = breakTextIntoChunks(currentTask.courseTask?.topics_material) || [];
    const easyExplanation = breakTextIntoChunks(currentTask.courseTask?.easyExplanation) || [];

    const keyTerms = currentTask.courseTask?.keyTerms || {};

    // Reset the current index when the currentTask changes
    useEffect(() => {
        setCurrentIndex(0);
    }, [currentTask]);

    const nextCard = () => {
        if (currentIndex < (showEasyExplanation ? easyExplanation : topicsMaterial).length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const prevCard = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const progress = Math.round(((currentIndex + 1) / (showEasyExplanation ? easyExplanation : topicsMaterial).length) * 100);

    // Handle toggle between easyExplanation and topics_material
    const toggleText = () => {
        setShowEasyExplanation(!showEasyExplanation);
        setCurrentIndex(0); // Reset the index when switching between texts
    };

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ margin: 0 }}><strong>{currentTask.content}</strong></p>
                        {(currentTask.courseTask?.easyExplanation) ? (
                            <Button onClick={toggleText} basic size="small">
                                {showEasyExplanation ? 'Show Matter-of-fact Explanation' : 'Show Easy Explanation'}
                            </Button>
                        ) : null}
                    </div>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <p>{currentTask.description}</p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    {currentTask.courseTask && (
                        <Segment>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Button 
                                    icon 
                                    basic
                                    onClick={prevCard} 
                                    disabled={currentIndex === 0} 
                                    style={{ marginRight: '10px', boxShadow: 'none' }} 
                                >
                                    <Icon name='chevron left' />
                                </Button>
                                <div style={{ flex: 1, textAlign: 'center' }}>
                                    {(showEasyExplanation ? easyExplanation : topicsMaterial).length > 0 && (
                                        <div>
                                            <ReactMarkdown>{(showEasyExplanation ? easyExplanation : topicsMaterial)[currentIndex]}</ReactMarkdown>
                                            <Progress percent={progress} progress indicating />
                                        </div>
                                    )}
                                </div>
                                <Button 
                                    icon 
                                    basic
                                    onClick={nextCard} 
                                    disabled={currentIndex === (showEasyExplanation ? easyExplanation : topicsMaterial).length - 1} 
                                    style={{ marginRight: '10px', boxShadow: 'none' }} 
                                >
                                    <Icon name='chevron right' />
                                </Button>
                            </div>
                        </Segment>
                    )}
                </Grid.Column>
            </Grid.Row>
            {currentTask.courseTask?.keyTerms ? <CollapsibleKeyTerms keyTerms={keyTerms}/> : null}
        </Grid>
    );
};

export default LearningComponent;
