import React, { useState } from 'react';
import { Accordion, Icon, Segment, List, Header, Label, Button, Modal, Form } from 'semantic-ui-react';
import { mapMajorMinorToDisplay, mapTechnologyToDisplay, sanitizeString } from '../displayHelpers';
import { toast } from 'react-toastify';
import { analytics } from '../firebase-config';
import { logEvent } from "firebase/analytics";

const isOwner = (ownerId) => {
    return localStorage.getItem('userId') === ownerId;
};

const Resume = ({ resume, editPdf }) => {
    const [pdfModalOpen, setPdfModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDownloadPdf = () => {
        if (analytics) {
            logEvent(analytics, 'download_pdf', {});
        } else {
            console.log('Analytics is not initialized');
        }
        setPdfModalOpen(true);
    };

    const handleSubmitPdfModal = async () => {
        setPdfModalOpen(false);

        // Make a request to the server endpoint for PDF generation
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/resume/generate-pdf`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(formData)
            }
        );
        const blob = await response.blob();

        // Create a link and trigger the download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        const filename = `${formData.fullName} Resume`;
        link.download = `${filename}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDeleteResume = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND}/api/resume/${resume._id}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                }
            );
            if (response.ok) {
                toast.success('Successfully deleted resume!');
                window.location.reload()
            } else {
                throw new Error('Failed to delete resume');
            }
        } catch (error) {
            toast.error('There was an error deleting your resume: ' + error.message);
        } finally {
            setDeleteModalOpen(false);
        }
    };

    const [activeIndex, setActiveIndex] = useState([]);
    const renderSkills = (skills) => skills?.map(skill => <Label key={skill}>{mapTechnologyToDisplay(skill)}</Label>);

    const handleClick = (index) => {
        const newIndex = activeIndex.includes(index) ? activeIndex.filter(i => i !== index) : [...activeIndex, index];
        setActiveIndex(newIndex);
    };

    const formatDate = (dateString) => {
        if (!dateString) {
            return 'Present';
        }
        return new Date(dateString).toLocaleDateString();
    };

    return (
        <Segment.Group>
            <Segment>
                <Accordion styled fluid>
                    {/* Personal Details Section */}
                    <Accordion.Title active={activeIndex.includes(0)} index={0} onClick={() => handleClick(0)}>
                        <Icon name='dropdown' />
                        Personal Details
                    </Accordion.Title >
                    <Accordion.Content active={activeIndex.includes(0)}>
                        <Segment>
                            <List>
                                <List.Item>Full Name: {resume.personalDetails.fullName}</List.Item>
                                <List.Item>GitHub: {resume.personalDetails.githubProfile}</List.Item>
                                <List.Item>LinkedIn: {resume.personalDetails.linkedInProfile}</List.Item>
                            </List>
                        </Segment>
                    </Accordion.Content>

                    {/* Skills Section */}
                    <Accordion.Title active={activeIndex.includes(1)} index={1} onClick={() => handleClick(1)}>
                        <Icon name='dropdown' />
                        Skills
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex.includes(1)}>
                        <Segment>
                            {renderSkills(resume.skills)}
                        </Segment>
                    </Accordion.Content>

                    {/* Education Section */}
                    <Accordion.Title active={activeIndex.includes(2)} index={2} onClick={() => handleClick(2)}>
                        <Icon name='dropdown' />
                        Education
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex.includes(2)}>
                        {resume.education.map((edu) => (
                            <Segment key={edu._id}>
                                <Header as='h4'>{edu.institution}</Header>
                                <p>Degree: {sanitizeString(edu.degree)}</p>
                                <p>Major: {edu.major.map(mapMajorMinorToDisplay).join(", ")}</p>
                                <p>Minor: {edu.minor.map(mapMajorMinorToDisplay).join(", ")}</p>
                                <p>Relevant Courses: {edu.relevantCourses.map(sanitizeString).join(", ")}</p>
                                <p>Graduation Year: {edu.graduationYear}</p>
                                {edu.gpa ? <p>GPA: {edu.gpa}/4.00</p> : null}
                            </Segment>
                        ))}
                    </Accordion.Content>

                    {/* Work Experiences Section */}
                    <Accordion.Title active={activeIndex.includes(3)} index={3} onClick={() => handleClick(3)}>
                        <Icon name='dropdown' />
                        Work Experiences
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex.includes(3)}>
                        {resume.workExperiences.map((work) => (
                            <Segment key={work._id}>
                                <Header as='h4'>{work.jobTitle} at {work.company} | {work.location}</Header>
                                <p>From: {formatDate(work.startDate)} To: {formatDate(work.endDate)}</p>
                                {resume.workContributions.filter(contrib => contrib.project === work._id).map(contrib => (
                                    <List key={contrib._id}>
                                        <List.Item>Description: {contrib.description}</List.Item>
                                        <List.Item>Impact: {contrib.impact}</List.Item>
                                        <List.Item>Technologies Used: {renderSkills(contrib.technologiesUsed)}</List.Item>
                                    </List>
                                ))}
                            </Segment>
                        ))}
                    </Accordion.Content>

                    {/* Personal Projects Section */}
                    <Accordion.Title active={activeIndex.includes(4)} index={4} onClick={() => handleClick(4)}>
                        <Icon name='dropdown' />
                        Personal Projects
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex.includes(4)}>
                        {resume.personalProjects.map((proj) => (
                            <Segment key={proj._id}>
                                <Header as='h4'>{proj.projectTitle}</Header>
                                <p>Demo Link: {proj.demoLink}</p>
                                {resume.projectContributions.filter(contrib => contrib.project === proj._id).map(contrib => (
                                    <List key={contrib._id}>
                                        <List.Item>Description: {contrib.description}</List.Item>
                                        <List.Item>Impact: {contrib.impact}</List.Item>
                                        <List.Item>Technologies Used: {renderSkills(contrib.technologiesUsed)}</List.Item>
                                    </List>
                                ))}
                            </Segment>
                        ))}
                    </Accordion.Content>
                </Accordion>
            </Segment>
            <Segment>
                {
                    isOwner(resume.user) ?
                        <>
                            <Button onClick={handleDownloadPdf}>Download PDF</Button>
                            <Button onClick={editPdf}>Edit Resume</Button>
                            <Button color="red" onClick={() => setDeleteModalOpen(true)}>Delete Resume</Button>
                        </>
                        : null
                }
                <Modal
                    size='mini'
                    open={pdfModalOpen}
                    onClose={() => setPdfModalOpen(false)}
                >
                    <Modal.Header>Please add the following information to add on PDF</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Input
                                label='Full Name'
                                name='fullName'
                                value={formData.fullName}
                                onChange={handleInputChange}
                                required
                            />
                            <Form.Input
                                label='Email'
                                name='email'
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                            <Form.Input
                                label='Phone'
                                name='phone'
                                value={formData.phone}
                                onChange={handleInputChange}
                                required
                            />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={() => setPdfModalOpen(false)}>Cancel</Button>
                        <Button positive onClick={handleSubmitPdfModal}>Download</Button>
                    </Modal.Actions>
                </Modal>
                <Modal
                    size='mini'
                    open={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                >
                    <Modal.Header>Delete Resume</Modal.Header>
                    <Modal.Content>
                        <p>Are you sure you want to delete your resume? This action cannot be undone.</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={() => setDeleteModalOpen(false)}>No</Button>
                        <Button positive onClick={handleDeleteResume}>Yes, Delete It</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </Segment.Group>
    );
};

export default Resume;
