import { Menu, Icon } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { allowFeature } from './feature_flags_service';
import "./App.css";
import { analytics } from './firebase-config';
import { logEvent } from "firebase/analytics";

const MenuBar = ({ flags, isLoggedIn, isAdmin, unreadCount }) => {
    const location = useLocation(); // Get the current location
    const [activeItem, setActiveItem] = useState(location.pathname); // Set the active item based on the current path
    const [isMobile, setIsMobile] = useState(false); // State to detect mobile view

    // Detect if the screen is mobile-sized
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        // Initial check
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleItemClick = (e, { name }) => {
        if (analytics) {
            logEvent(analytics, 'switch_tabs', {
                content_type: name,
                content_id: name,
                items: [{ name }]
            });
        } else {
            console.log('Analytics is not initialized');
        }
        setActiveItem(name)
    };

    return (
        isLoggedIn && (
            <Menu pointing secondary size="large" className={isMobile ? 'mobile-menu' : ''}>
                <Menu.Item
                    name="profile"
                    active={activeItem === '/profile'}
                    onClick={handleItemClick}
                    as={Link}
                    to="/profile"
                >
                    {isMobile ? <Icon name="user" /> : 'Profile'}
                </Menu.Item>
                {(isAdmin || allowFeature(flags, 'projects')) && (
                    <Menu.Item
                        name="projects"
                        active={activeItem === '/projects'}
                        onClick={handleItemClick}
                        as={Link}
                        to="/projects"
                    >
                        {isMobile ? <Icon name="folder open" /> : 'Projects & Courses'}
                    </Menu.Item>
                )}
                <Menu.Item
                    name="jobs"
                    active={activeItem === '/jobs'}
                    onClick={handleItemClick}
                    as={Link}
                    to="/jobs"
                >
                    {isMobile ? <Icon name="briefcase" /> : 'Jobs'}
                </Menu.Item>
                <Menu.Item
                    name="users"
                    active={activeItem === '/users'}
                    onClick={handleItemClick}
                    as={Link}
                    to="/users"
                >
                    {isMobile ? <Icon name="users" /> : 'Resumes & Users'}
                </Menu.Item>
                {isAdmin && (
                    <Menu.Item
                        name="admin_console"
                        active={activeItem === '/admin_console'}
                        onClick={handleItemClick}
                        as={Link}
                        to="/admin_console"
                    >
                        {isMobile ? <Icon name="settings" /> : 'Admin Console'}
                    </Menu.Item>
                )}
                <Menu.Item
                    name="notifications"
                    active={activeItem === '/notifications'}
                    onClick={handleItemClick}
                    as={Link}
                    to="/notifications"
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {isMobile ? <Icon name="bell" /> : 'Notifications'}
                        {unreadCount > 0 && (
                            <span className="notification-count">{unreadCount}</span>
                        )}
                    </div>
                </Menu.Item>
            </Menu>
        )
    );
};

export default MenuBar;
