import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

function LandingPage() {
  const navigate = useNavigate(); // Get navigate function from useNavigate

  useEffect(() => {
    // Listener for postMessage from iframe
    const handleMessage = (event) => {
      if (event.data.action === "navigate") {
        // Use navigate from react-router to update the URL
        navigate(event.data.route); // Use navigate() instead of history.pushState()
      }
    };

    // Add event listener for message events from the iframe
    window.addEventListener("message", handleMessage);

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [navigate]); // Add navigate as a dependency

  return (
    <div style={{ height: "100vh", margin: 0 }}>
      <iframe
        src="/landing-page/index.html" // Reference the landing page inside the new subfolder
        width="100%" // Make the iframe take up 100% width
        height="100%" // Make the iframe take up 100% height
        style={{ border: "none" }}
        title="Landing Page"
      />
    </div>
  );
}

export default LandingPage;
