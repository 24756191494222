import React, { useState, useEffect } from 'react';
import { Grid, Card, Icon, Accordion, Button, List, Label, Segment, Modal, Popup, Loader } from 'semantic-ui-react';
import { mapTechnologyToDisplay, shorthandToDisplayTimeline, createLabel, sanitizeString, formatParticipantsAsList, getCountryIcon, getProjectTitle, getLabelForTime, LinkButton } from '../displayHelpers';
import { useParams, useNavigate } from 'react-router-dom';
import { checkTokenValidity } from '../common_frontend_requests';
import TaskTimeline from './Timeline';
import { toast } from 'react-toastify';
import LongListLabel from './LongListLabel';
import DeleteProjectButton from './DeleteProjectButton';
import ReadOnlyTasksList from './TimelineTasksListReadOnly';
import CopyToClipboard from './CopyToClipboard';
import ApplyToProjectModal from './ApplyToProjectModal';
import CourseBuilder from './CourseBuilder';
import CourseInterface from './CourseInterface';
import GroupChatModal from './ChatModal';

{/* FIXME: Disabled Discord chat */}
// const DiscordInviteButton = ({ inviteLink }) => {
//     return (
//         <Container textAlign='center' style={{ marginTop: '20px' }}>
//             <Button
//                 color='purple'
//                 href={inviteLink}
//                 target='_blank'
//                 rel='noopener noreferrer'
//             >
//                 Join Discord Chat
//             </Button>
//         </Container>
//     );
//   };

const modalOpenText = (projectType) => {
    switch (projectType) {
      case 'teachable_project':
        return 'Manage Course'
      default:
        return 'Manage Project'
    }
  }

const ProjectPage = () => {
    const isPaidMember = localStorage.getItem('isPro') === 'true';

    const { project_id } = useParams();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [activeIndex, setActiveIndex] = useState([]);
    const [timelineModal, setTimelineModal] = useState(false);
    const [applicationModalOpen, setApplicationModalOpen] = useState(false);
    const [project, setProject] = useState(null); // Set initial state to `null`
    const [application, setApplication] = useState({
        role: '',
        whyInterested: '',
        timeCommitment: ''
      });
      const [projectTimeline, setProjectTimeline] = useState(null);
      const [loadingTimeline, setLoadingTimeline] = useState(false);
      const [likesCount, setLikesCount] = useState(null);
      const [isLiked, setIsLiked] = useState(null);
      const [isChatOpen, setIsChatOpen] = useState(false);
      const [isLoading, setIsLoading] = useState(true);
  
      const toggleLike = async (projectId) => {
          const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/like/${projectId}`, {
              method: 'PATCH',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
              },
          });
      
          if (!response.ok) {
              const errorText = await response.text();
              console.error(`Error liking project: ${errorText}`);
              toast.error(`Error liking project: ${errorText}`);
              return;
          };
      
          const data = await response.json();
          setLikesCount(data.likesCount);
          setIsLiked(data.liked);
      };
      
      const fetchProjectTimeline = async () => {
          setLoadingTimeline(true);
          try {
              const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/project_timeline_read_only/${project.project_timeline}`, {
                  method: 'GET',
                  headers: {
                      'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                      'Content-Type': 'application/json',
                  },
              });
              if (!response.ok) throw new Error('Failed to fetch project timeline');
              const timelineData = await response.json();
              setProjectTimeline(timelineData);
          } catch (error) {
              console.error('Error fetching project timeline:', error);
              toast.error('Failed to fetch project timeline');
          }
          setLoadingTimeline(false);
      };
      

    const handleInputChange = (e, { name, value }) => {
    setApplication(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmitApplication = async (projectId, solo=false) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/apply/${projectId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            body: JSON.stringify({
              role: application.role,
              whyInterested: application.whyInterested,
              timeCommitment: application.timeCommitment,
              solo
            })
          });
          if (response.status === 409) {
            toast.warning("You have already applied to this project");
            return
          }
          if (!response.ok) {
            throw new Error('Failed to submit application');
          }
          toggleApplicationModal(); // Close the modal on success
          setApplication({ role: '', whyInterested: '', timeCommitment: '' }); // Reset application form
          toast.success('Application submitted successfully!');
          const result = await response.json()
          return result
        } catch (error) {
          console.error('Error submitting application:', error);
          toast.error('Failed to submit application')
        }
      };

      const validateToken = async () => {
        const data = await checkTokenValidity();
        if (data.isValid) {
          setIsLoggedIn(true);
        } else {
          // If the token is invalid, redirect to the login page with the `redirect` query parameter
          navigate(`/login?redirect=/projects/${project_id}`);
        }
      };

    const toggleTimelineModal = () => {
        setTimelineModal(!timelineModal)
    }

    const toggleApplicationModal = () => {
        setApplicationModalOpen(!applicationModalOpen)
    }

    const isCreator = (creatorId) => {
        return localStorage.getItem('userId') === creatorId;
    };

    const isParticipant = (project) => {
        return project.participants.some(p => p.user._id === localStorage.getItem('userId'))
    }

    const navigate = useNavigate();

    const fetchProjectDetails = async (id) => {
        setIsLoading(true); // Start loading
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/get_one/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
            });
            if (response.ok) {
                const responseData = await response.json();
                setProject(responseData);
            } else {
                console.error('Failed to fetch project');
            }
        } catch (error) {
            console.error('Error fetching project:', error);
        } finally {
            setIsLoading(false); // End loading
        }
    };

    // Fetch project details when the component mounts or the project_id changes
    useEffect(() => {
        validateToken()
        fetchProjectDetails(project_id);
    }, [project_id]);

    useEffect(() => {
        if (project) {
            fetchProjectTimeline();
        }
    }, [project])

    useEffect(() => {
        if (project) {
            setLikesCount(project.likes ? project.likes.length : 0)
            setIsLiked(project.likes && project.likes.includes(localStorage.getItem('userId')))
        }
    }, [project])


    const handleAccordionClick = (index) => {
        const newIndex = activeIndex.includes(index)
            ? activeIndex.filter((i) => i !== index)
            : [...activeIndex, index];
        setActiveIndex(newIndex);
    };

    const isActive = (index) => activeIndex.includes(index);

    const viewingUser = () => {
        return localStorage.getItem('userId')
    }

    const pickTimelineDisplay = (project, toggleTimelineModal, isCreator) => {
        if (project.projectType === 'teachable_project') {
          if (isCreator(project.project_creator)) {
            return <CourseBuilder projectId={project._id} toggleModal={toggleTimelineModal} fetchProjects={()=>{}}/>
          }
          return <CourseInterface projectId={project._id} />
        }
        return <TaskTimeline projectId={project._id} toggleModal={toggleTimelineModal} fetchProjects={()=>{}}/>
      }

    return (
        isLoading ? (
            <Segment>
                <Loader active inline="centered">Loading Project Details...</Loader>
            </Segment>
        ) : (
        project ? (
            <Segment>
                <Card style={{margin: 5}} fluid className="fade-in">
                <Card.Content>
                    <Card.Header>
                        {getProjectTitle(project)}
                        {['study_group'].includes(project.projectType) ? 
                            <Label>
                                <Icon name='linkify' />
                                <a href={project.course_link} target='_blank' rel='noopener noreferrer'>{project.course_title}</a>
                            </Label> : null    
                        }
                        <br/>
                        <CopyToClipboard copyLink={`${process.env.REACT_APP_HOST}/projects/${project._id}`} copyText={'Copy project link'} />
                        {isCreator(project.project_creator) ?  createLabel('yellow', 'Creator', 'trophy', true) : null}
                        {project.projectType ? createLabel('teal', sanitizeString(project.projectType), 'chess', true) : null}
                        {project.estimatedCompletionTime ? getLabelForTime(project.estimatedCompletionTime) : null}
                        {project.beginner ? createLabel('olive', 'Beginner', 'leaf', true) : null}
                        <Popup
                            content={`${likesCount} user(s) have liked this project.`}
                            trigger={
                                <Button icon onClick={() => toggleLike(project._id)} style={{ backgroundColor: 'transparent', padding: 0, border: 'none' }}>
                                    <Icon name={isLiked ? "heart" : "heart outline"} color={isLiked ? "red" : undefined}/>
                                    {likesCount}
                                </Button>
                            }
                        />
                        {project.associated_job ? <Grid.Row>
                            <LinkButton color={'yellow'} link={`/jobs/${project.associated_job}`} buttonText={'See Associated Job'} iconName={'briefcase'}/>
                        </Grid.Row> : null}
                    </Card.Header>
                   {project.country ? <Card.Meta>
                    <span>Project Location: {getCountryIcon(project.country)}</span>
                    </Card.Meta> : null}
                    {
                        isCreator(project.project_creator) ? <DeleteProjectButton fetchProjects={() => {navigate(-1)}} projectId={project._id}/> : null
                    }
                    <Card.Description>
                        {project.participants &&  project.participants.length ? <LongListLabel labels={formatParticipantsAsList(project.participants)} color="pink"/> : null}
                    {project.allow_off_region_participation && (
                        <Label color="green">Off-Region Participation Allowed</Label>
                    )}
                    </Card.Description>
                    <Card.Description>
                        <p style={{ margin: 5 }}>{project.background}</p>
                    </Card.Description>
                    {project.projectType === 'teachable_project' && project.topics.map((skill, index) => (
                    <Label key={index} color="olive" style={{ margin: '2px' }}>
                        {skill}
                    </Label>
                    ))}
                    {(project.features?.length || project.project_timeline) ? <Accordion fluid styled style={{marginTop: '5px'
                    
                    }}>
                        {project.features?.length ? <Accordion.Title
                            active={isActive(0)}
                            index={0}
                            onClick={() => handleAccordionClick(0)}
                        >
                            <Icon name='dropdown' />
                            Features
                        </Accordion.Title> : null}
                        {project.features?.length ? <Accordion.Content active={isActive(0)}>
                            <List>
                                {project.features?.map((feature, featureIndex) => (
                                    <List.Item icon='gift' key={featureIndex} content={feature} />
                                ))}
                            </List>
                        </Accordion.Content> : null}
                        <React.Fragment>
                            <Accordion.Title
                                active={isActive(1)}
                                index={1}
                                onClick={() => {
                                    handleAccordionClick(1);
                                    if (!projectTimeline) fetchProjectTimeline();
                                }}
                            >
                                <Icon name='dropdown' />
                                Project Timeline
                            </Accordion.Title>
                            <Accordion.Content active={isActive(1)}>
                                {loadingTimeline ? (
                                    <div>Loading timeline...</div>
                                ) : projectTimeline && projectTimeline.tasks && projectTimeline.tasks.length > 0 ? (
                                    <ReadOnlyTasksList tasks={projectTimeline.tasks} showDetails={false}/>
                                ) : (
                                    <div>No timeline data available.</div>
                                )}
                            </Accordion.Content>
                        </React.Fragment>
                    </Accordion> : null}
                </Card.Content>
                <Card.Content extra>
                    <LongListLabel labels={project && project.technologies.map(mapTechnologyToDisplay)} color={"blue"} />
                    {(isLoggedIn && (isCreator(project.project_creator) || isParticipant(project))) && !project.solo ? <Segment>
                        <Grid centered>
                            <Grid.Row>
                            <Grid.Column textAlign="center">
                                <Button primary onClick={() => setIsChatOpen(true)}>
                                Open Group Messaging
                                </Button>
                            </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <GroupChatModal
                            isOpen={isChatOpen}
                            onClose={() => setIsChatOpen(false)}
                            projectId={project._id}
                            viewingUser={viewingUser()}
                        />
                    </Segment> : null}
                    {/* FIXME: Disable Discord chat */}
                    {/* {
                        (isLoggedIn && (isCreator(project.project_creator) || isParticipant(project)) && project.discord_invite_link) ? <DiscordInviteButton 
                        inviteLink={project.discord_invite_link}
                        /> : null
                    } */}
                </Card.Content>
                    {isLoggedIn && (isCreator(project.project_creator) || isParticipant(project)) && (
                        <>
                            <Button secondary onClick={() => toggleTimelineModal()}>
                            {modalOpenText(project.projectType)}
                            </Button>
                            <Modal open={timelineModal} onClose={() => toggleTimelineModal(project._id)}>
                            <Modal.Header>{modalOpenText(project.projectType)}</Modal.Header>
                            <Modal.Content>
                                {pickTimelineDisplay(project, toggleTimelineModal, isCreator)}
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative onClick={() => toggleTimelineModal(project._id)}>Done</Button>
                            </Modal.Actions>
                            </Modal>
                        </>
                        )}

                        {(!isCreator(project.project_creator) && !isParticipant(project)) && (
                        <ApplyToProjectModal 
                            project={project}
                            toggleApplicationModal={toggleApplicationModal}
                            applicationModalOpen={applicationModalOpen}
                            application={application}
                            handleInputChange={handleInputChange}
                            handleSubmitApplication={handleSubmitApplication}
                        />
                        )}
                </Card>
            </Segment>
        ) : null)
    );
};

export default ProjectPage;
