import React, { useState, useEffect } from 'react';
import { Button, Segment, Progress, Icon, Grid, List } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';

const TextChunker = ({ text }) => {
    // Function to break text into chunks based on newlines and filter out empty chunks
    const breakTextIntoChunks = (text) => {
        if (!text) return [];
        // Split the text by newline, trim and remove empty chunks
        return text.split('\n')
            .map((chunk, index) => chunk.trim()) // Trim each chunk
            .filter(chunk => chunk.length > 0)  // Remove empty chunks
            .map((chunk, index) => ({ id: index, content: chunk }));
    };

    const [chunks, setChunks] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        setChunks(breakTextIntoChunks(text));
    }, [text]);

    const nextChunk = () => {
        if (currentIndex < chunks.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const prevChunk = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const progress = Math.round(((currentIndex + 1) / chunks.length) * 100);

    return (
        <Segment style={{ padding: '20px' }}>
            {chunks.length > 0 ? (
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Button
                                    icon
                                    basic
                                    onClick={prevChunk}
                                    disabled={currentIndex === 0}
                                    style={{ marginRight: '10px', boxShadow: 'none' }}
                                >
                                    <Icon name='chevron left' />
                                </Button>
                                <List divided selection style={{ flex: 1, textAlign: 'center' }}>
                                    <List.Item key={chunks[currentIndex].id}>
                                        <ReactMarkdown>{chunks[currentIndex].content}</ReactMarkdown>
                                    </List.Item>
                                </List>
                                <Button
                                    icon
                                    basic
                                    onClick={nextChunk}
                                    disabled={currentIndex === chunks.length - 1}
                                    style={{ marginLeft: '10px', boxShadow: 'none' }}
                                >
                                    <Icon name='chevron right' />
                                </Button>
                            </div>
                            <Progress percent={progress} progress indicating />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            ) : (
                <p>No text available.</p>
            )}
        </Segment>
    );
};

export default TextChunker;
