import React, { useState } from 'react';
import { Input, Icon, Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import '../App.css';
import { analytics } from '../firebase-config';
import { logEvent } from "firebase/analytics";

const AIResumeUploader = ({ setOpen }) => {
  const [file, setFile] = useState(null);
  const [showUploadInput, setShowUploadInput] = useState(false); // State to control the upload input visibility
  const [loading, setLoading] = useState(false); // State to manage loading
  const [disabled, setDisabled] = useState(false); // State to manage button disable

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // Capture the file from input
  };

  const handleParseResume = async () => {
    if (analytics) {
      logEvent(analytics, 'submit_resume_for_ai_parse', {});
    } else {
        console.log('Analytics is not initialized');
    }
    if (!file) {
      toast.error('Please select a file to parse');
      return;
    }

    setLoading(true); // Start loading
    setDisabled(true); // Disable buttons while loading

    const formData = new FormData();
    formData.append('file', file);
    formData.append('promptText', 'Parse this resume'); // You can modify this as per your prompt

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/users/parse-resume`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error('Failed to parse resume. Please retry upload.');
      }

      toast.success('Resume parsed successfully!');
    } catch (error) {
      console.error('Error parsing resume:', error);
      toast.error('Error parsing resume. Please retry upload.');
    } finally {
      setLoading(false); // Stop loading
      setDisabled(false); // Enable buttons after loading
    }
  };

  const clearFile = () => {
    setFile(null);
    setShowUploadInput(false); // Hide the upload input when clearing the file
  };

  const handleUploadButtonClick = () => {
    if (analytics) {
      logEvent(analytics, 'parse_resume_with_ai', {});
    } else {
        console.log('Analytics is not initialized');
    }
    setShowUploadInput(true); // Show the upload input when the button is clicked
  };

  const handleCloseButtonClick = () => {
    setShowUploadInput(false); // Hide the upload input and show the button again
    clearFile(); // Clear the selected file
  };

  return (
    <div>
      {showUploadInput ? ( // Render input and close button if uploading
        <div className="upload-container">
          <Input
            type="file"
            onChange={handleFileChange}
            id="fileInput"
          />
          <Button.Group>
            <Button icon onClick={handleCloseButtonClick} disabled={loading}>
              <Icon name="close" />
            </Button>
            {file ? (
              <Button 
                primary 
                onClick={handleParseResume} 
                loading={loading} // Show loading state
                disabled={disabled} // Disable while loading
              >
                Upload Resume
              </Button>
            ) : null}
          </Button.Group>
        </div>
      ) : (
        <div>
          <Button primary onClick={handleUploadButtonClick}>
            <Icon name="magic" />
            Parse Resume with AI
          </Button>
          <Button onClick={() => setOpen(true)}>
            Manually Build Resume
          </Button>
        </div>
      )}
    </div>
  );
};

export default AIResumeUploader;
