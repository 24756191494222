import React, { useState, useEffect } from 'react';
import { Button, Form, Accordion, Icon, Segment, Checkbox } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import ReactMarkdown from 'react-markdown';

const Assessment = ({ questions, fetchProjectDetails, currentTask, project }) => {
    const [answers, setAnswers] = useState({});
    const [loading, setLoading] = useState(false)

    const handleAssessmentSubmit = async (answers) => {
        setLoading(true)
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/tasks/${currentTask.id}/submitAssessment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
                body: JSON.stringify({ answers })
            });

            const result = await response.json();

            if (response.ok && result.pass) {
                toast.success('Assessment passed!');
                // Refetch tasks and project details if the assessment is passed
                fetchProjectDetails(project.project_timeline);
                setLoading(false)
            } else {
                toast.error(
                    <ReactMarkdown>{result.message || 'Assessment failed. Please try again.'}</ReactMarkdown>, {
                        autoClose: 10000,  // Toast will close after 7 seconds
                    }
                );
                setLoading(false)
            }
        } catch (error) {
            console.error('Error submitting assessment:', error);
            toast.error('Error submitting assessment');
            setLoading(false)
        }
    };

    const handleChange = (questionId, optionId) => {
        setAnswers({
            ...answers,
            [questionId]: optionId
        });
    };

    const allQuestionsAnswered = questions.length > 0 && questions.every(question => answers.hasOwnProperty(question.id));

    const handleSubmit = () => {
        handleAssessmentSubmit(answers);
    };

    return (
            <Form>
                {questions.map(question => (
                    <Form.Field key={question.id}>
                        <label>{question.text}</label>
                        <Form.Group grouped>
                            {question.options.map(option => (
                                <Form.Field key={option.id}>
                                    <Checkbox
                                        radio
                                        label={option.text}
                                        name={question.id}
                                        value={option.id}
                                        checked={answers[question.id] === option.id}
                                        onChange={() => handleChange(question.id, option.id)}
                                    />
                                </Form.Field>
                            ))}
                        </Form.Group>
                    </Form.Field>
                ))}
                <Button 
                    onClick={handleSubmit} 
                    disabled={!allQuestionsAnswered || loading}
                    loading={loading}
                >
                    Submit Answers
                </Button>
            </Form>
    );
};

// TODO: assessment is not loading to reveal the new current task
const CollapsibleAssessment = ({ currentTask, fetchProjectDetails, project }) => {
    const [activeIndex, setActiveIndex] = useState(0); // Controls whether the accordion is open or not
    const [currentAssessmentQuestions, setCurrentAssessmentQuestions] = useState([]);

    // Handle the accordion click to toggle visibility of assessment
    const handleAccordionClick = () => {
        setActiveIndex(activeIndex === 0 ? -1 : 0); // Toggle accordion section
    };

    const fetchAssessment = async (taskId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/tasks/${taskId}/fetchAssessment`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
            });

            if (response.ok) {
                const questions = await response.json();

                // Format the questions to be used in the Assessment component
                const formattedQuestions = questions.map(question => ({
                    id: question._id,
                    text: question.question,
                    options: question.answers.map(option => ({
                        id: option._id,
                        text: option.answer
                    }))
                }));

                setCurrentAssessmentQuestions(formattedQuestions);
            } else {
                throw new Error('Failed to fetch assessment');
            }
        } catch (error) {
            console.error('Error fetching assessment:', error);
            toast.error('Error fetching assessment');
        }
    };

    // Fetch assessment when the task is available and it hasn't been fetched yet
    useEffect(() => {
        if (currentTask) {
            fetchAssessment(currentTask._id); // Fetch assessment if not yet fetched
        }
    }, [currentTask?._id]);

    return (
        <div style={{ width: '100%' }}>
            <Accordion styled>
                <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={handleAccordionClick}
                    style={{ cursor: 'pointer', marginTop: '10px' }}
                >
                    <Icon name={activeIndex === 0 ? 'caret down' : 'caret right'} />
                    Assessment
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                    {currentAssessmentQuestions && (
                        <Segment>
                            <Assessment
                                questions={currentAssessmentQuestions}
                                fetchProjectDetails={fetchProjectDetails}
                                project={project}
                                currentTask={currentTask}
                            />
                        </Segment>
                    )}
                </Accordion.Content>
            </Accordion>
        </div>
    );
};

export default CollapsibleAssessment;
