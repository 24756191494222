import React, { useState, useEffect } from 'react';
import { Feed, Message, Icon, Button, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import CoursePills from './CoursePills'; // Import the CoursePills component

const timeAgo = (date) => {
  return formatDistanceToNow(new Date(date), { addSuffix: true });
};

const mapTypeToDisplay = (type) => {
  switch (type) {
    case 'UserCreated':
      return 'New user joined!';
    case 'JobApplicationStatusChanged':
      return "Someone's making progress :)";
    case 'NewJobsUpload':
      return 'Fresh Jobs added!';
    case 'ResumeAdded':
      return 'New resume added!';
    case 'ProjectAdded':
      return 'New project added!';
    case 'UserSignedUpForCourse':
      return 'Someone started a Crash Course!';
    case 'HotCourse':
      return 'Hot Course Alert!';
    case 'ResumeReviewed':
      return 'Special Resume!';
    case 'JobAdded':
      return 'New Job added!';
    case 'CrashCourseAdded':
      return 'Crash Course added!';
    case 'CourseCompleted':
      return 'Course Completed!';
    case 'NewsUpdate':
      return 'Latest Tech News!';
    default:
      return 'Unknown event :(';
  }
};

const mapTypeToIcon = (type) => {
  switch (type) {
    case 'UserCreated':
      return 'user circle';
    case 'JobApplicationStatusChanged':
      return 'briefcase';
    case 'NewJobsUpload':
      return 'rocket';
    case 'ResumeAdded':
      return 'briefcase';
    case 'ProjectAdded':
      return 'paint brush';
    case 'UserSignedUpForCourse':
      return 'handshake outline';
    case 'HotCourse':
      return 'fire';
    case 'ResumeReviewed':
      return 'file alternate';
    case 'JobAdded':
      return 'briefcase';
    case 'CrashCourseAdded':
      return 'rocket';
    case 'CourseCompleted':
      return 'trophy';
    case 'NewsUpdate':
      return 'newspaper outline';
    default:
      return 'qq';
  }
};

const mapTypeToIconColor = (type) => {
  switch (type) {
    case 'UserCreated':
      return 'blue';
    case 'JobApplicationStatusChanged':
      return 'green';
    case 'NewJobsUpload':
      return 'purple';
    case 'ResumeAdded':
      return 'brown';
    case 'ProjectAdded':
      return 'teal';
    case 'UserSignedUpForCourse':
      return 'olive';
    case 'HotCourse':
      return 'orange';
    case 'ResumeReviewed':
      return 'yellow';
    case 'JobAdded':
      return 'blue';
    case 'CrashCourseAdded':
      return 'green';
    case 'CourseCompleted':
      return 'yellow';
    case 'NewsUpdate':
      return 'blue';
    default:
      return 'black';
  }
};

const EventFeedItem = ({ event }) => {
  const enhanceDescriptionWithLink = (eventDesc) => {
    const regex = new RegExp(`(check it out[.!]*)$`, 'i');
    const cleanedText = eventDesc.replace(regex, '').trim();
    if (event.additionalInfo && event.additionalInfo.nav_link) {
      return (
        <>
          {cleanedText}
          <Link to={event.additionalInfo.nav_link} style={{ marginLeft: '5px' }}>
            <strong>Check it out!</strong>
          </Link>
        </>
      );
    }
    return eventDesc;
  };

  return (
    <Feed.Event>
      <Feed.Label>
        <Icon name={mapTypeToIcon(event.eventType)} color={mapTypeToIconColor(event.eventType)} />
      </Feed.Label>
      <Feed.Content>
        {event.eventType === 'NewsUpdate' ? (
          <>
            <Feed.Summary>
              {event.additionalInfo.articleLink ? (
                <a href={event.additionalInfo.articleLink} target="_blank" rel="noopener noreferrer">
                  {event.additionalInfo.newsHeading}
                </a>
              ) : (
                event.additionalInfo.newsHeading
              )}
              <Feed.Date>{timeAgo(event.createdAt)}</Feed.Date>
            </Feed.Summary>
            <Feed.Extra text>{event.eventDescription}</Feed.Extra>
            {event.additionalInfo.skills && (
              <Feed.Meta>
                {event.additionalInfo.skills.map((skill, index) => (
                  <Label key={index} color="blue" style={{ marginBottom: '5px' }}>
                    {skill}
                  </Label>
                ))}
              </Feed.Meta>
            )}
            {event.additionalInfo.technical_skills && (
              <>
                <br />
                <Feed.Meta>
                  {event.additionalInfo.technical_skills.map((skill, index) => (
                    <Label key={index} color="olive" style={{ marginBottom: '5px' }}>
                      {skill}
                    </Label>
                  ))}
                </Feed.Meta>
              </>
            )}
            {event.additionalInfo.associated_courses && (
              <CoursePills courses={event.additionalInfo.associated_courses} />
            )}
          </>
        ) : (
          <>
            <Feed.Summary>
              {mapTypeToDisplay(event.eventType)} <Feed.Date>{timeAgo(event.createdAt)}</Feed.Date>
            </Feed.Summary>
            <Feed.Extra text>
              {event.eventType === 'JobAdded' && event.additionalInfo.nav_link ? (
                <a href={event.additionalInfo.nav_link} target="_blank" rel="noopener noreferrer">
                  {event.eventDescription}
                </a>
              ) : (
                enhanceDescriptionWithLink(event.eventDescription)
              )}
            </Feed.Extra>
            {['ResumeAdded', 'ResumeReviewed'].includes(event.eventType) && (
              <Feed.Meta>
                Add your own Resume{' '}
                <Link to={'/profile'}>
                  <strong>here</strong>.
                </Link>
              </Feed.Meta>
            )}
          </>
        )}
      </Feed.Content>
    </Feed.Event>
  );
};

const EventsFeed = () => {
  const [events, setEvents] = useState([]);
  const [skip, setSkip] = useState(0);
  const limit = 10;

  useEffect(() => {
    loadMoreEvents();
  }, []);

  const loadMoreEvents = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/events?skip=${skip}&limit=${limit}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );

      const data = await response.json();
      setEvents([...events, ...data.events]);
      setSkip(skip + limit);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  return (
    <div
      style={{
        padding: '20px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {events.length ? (
        <Feed size="large">
          {events.map((event, index) => (
            <EventFeedItem key={index} event={event} />
          ))}
        </Feed>
      ) : (
        <Message info>No events available. Come back later...</Message>
      )}
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Button basic onClick={loadMoreEvents} primary>
          Load More...
        </Button>
      </div>
    </div>
  );
};

export default EventsFeed;
