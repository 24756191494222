import React, { useEffect, useState } from 'react';
import { Segment, Button, Loader, Label, ButtonGroup, Accordion, Icon } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import Editor from '@monaco-editor/react';
import ReactMarkdown from 'react-markdown';

const CodingProblemComponent = ({ codingProblemId, taskId }) => {
    const [codingProblem, setCodingProblem] = useState(null);
    const [testCases, setTestCases] = useState([]);
    const [code, setCode] = useState('// Write your code here');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0); // Control whether the entire component is expanded or collapsed

    // Fetch coding problem and test cases on mount
    useEffect(() => {
        const fetchCodingProblemAndTestCases = async () => {
            try {
                const codingProblemResponse = await fetch(`${process.env.REACT_APP_BACKEND}/api/tasks/${codingProblemId}/coding-problem`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                    },
                });

                if (!codingProblemResponse.ok) {
                    throw new Error('Failed to fetch coding problem.');
                }

                const problemData = await codingProblemResponse.json();
                setCodingProblem(problemData);
                setCode(problemData.boilerPlateCode || code);

                const testCasesResponse = await fetch(`${process.env.REACT_APP_BACKEND}/api/tasks/${codingProblemId}/test-cases`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                    },
                });

                if (!testCasesResponse.ok) {
                    throw new Error('Failed to fetch test cases.');
                }

                const testCasesData = await testCasesResponse.json();
                setTestCases(testCasesData);
            } catch (error) {
                console.error('Error fetching coding problem or test cases:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCodingProblemAndTestCases();
    }, [codingProblemId]);

    const handleCodeChange = (value) => {
        setCode(value);
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);

        try {
            const response = await fetch(`${process.env.REACT_APP.BACKEND}/api/tasks/${codingProblemId}/${taskId}/submitCodingAssessment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
                body: JSON.stringify({ code, language: codingProblem?.language }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to submit code.');
            }

            const result = await response.json();
            toast.success(result.message); // Display the result of the code submission
        } catch (error) {
            toast.error(error.message); // Display the error message to the user
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSeeSampleSolution = async () => {
        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/tasks/${codingProblemId}/sample-solution`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to fetch sample solution.');
            }

            const data = await response.json(); // Assuming solution is plain text
            setCode(data.sampleSolution);
            toast.success('Sample solution loaded.');
        } catch (error) {
            toast.error(error.message); // Display the error message to the user
        } finally {
            setLoading(false);
        }
    };

    const handleAccordionClick = () => {
        setActiveIndex(activeIndex === 0 ? -1 : 0); // Toggle accordion section
    };

    if (loading) {
        return <Loader active inline="centered">Loading Coding Problem...</Loader>;
    }

    if (!codingProblem || codingProblem.problemType === 'not_applicable') {
        return <Segment>No coding problem available for this task.</Segment>;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: '100%', height: '100%' }}>
            <Accordion styled>
                <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={handleAccordionClick}
                    style={{ cursor: 'pointer', width: '100%' }}
                >
                    <Icon name={activeIndex === 0 ? 'caret down' : 'caret right'} />
                    Coding Problem <Label>{codingProblem.language}</Label>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                    <Segment style={{ width: '100%' }}>
                        <ReactMarkdown>{codingProblem.problemDescription}</ReactMarkdown>
                        <h4>Test Cases</h4>
                        <ul>
                            {testCases.map((testCase) => (
                                <li key={testCase.testId}>
                                    <strong>Input:</strong> {testCase.input} <br />
                                    <strong>Expected Output:</strong> {testCase.expectedOutput}
                                </li>
                            ))}
                        </ul>
                        <h4>Write Your Code</h4>
                        <Editor
                            height="400px"
                            defaultLanguage={codingProblem.language || 'javascript'}
                            value={code}
                            onChange={handleCodeChange}
                            theme="vs-dark"
                            options={{
                                wordWrap: 'on',
                                minimap: { enabled: false },
                            }}
                        />
                        <div style={{ marginTop: '10px', textAlign: 'right' }}>
                            <ButtonGroup>
                                <Button
                                    primary
                                    loading={isSubmitting}
                                    onClick={handleSubmit}
                                    disabled={true || isSubmitting} // TODO: restore when boiler plate code is resumed
                                >
                                    Submit Code <Label style={{ margin: '2px' }}>Coming soon...</Label>
                                </Button>
                                <Button
                                    secondary
                                    onClick={handleSeeSampleSolution}
                                >
                                    See Sample Solution
                                </Button>
                            </ButtonGroup>
                        </div>
                    </Segment>
                </Accordion.Content>
            </Accordion>
        </div>
    );
};

export default CodingProblemComponent;
