import React from 'react';
import { List, ListItem, Grid, GridColumn, ListHeader, ListDescription, Icon } from 'semantic-ui-react';

const ReadOnlyTasksList = ({ tasks, showDetails=false }) => {
  return (
    <List>
      {tasks.map(task => (
        <ListItem key={task._id}>
          <Grid>
            <Grid.Row>
              <GridColumn width={1}>
                {task.taskStatus === 'Done' ? <Icon name='check circle outline' /> : <Icon name='circle outline' />}
              </GridColumn>
              <GridColumn width={15}>
                <ListHeader>{task.content}</ListHeader>
                {task.description && showDetails && <ListDescription>{task.description}</ListDescription>}
                {showDetails && <ListDescription>Start: <strong>{new Date(task.start).toISOString().split('T')[0]}</strong> | End: <strong>{new Date(task.end).toISOString().split('T')[0]}</strong></ListDescription>}
              </GridColumn>
            </Grid.Row>
          </Grid>
        </ListItem>
      ))}
    </List>
  );
};

export default ReadOnlyTasksList;
