import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Segment, Loader, Label, List, Button, Icon, Grid } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { checkTokenValidity } from '../common_frontend_requests';
import { LinkButton } from '../displayHelpers';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`;
    return formattedDate;
};

const JobDetailsPage = () => {
    const { job_id } = useParams(); // Get job_id from URL parameters
    const navigate = useNavigate();
    const [job, setJob] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const validateToken = async () => {
            const data = await checkTokenValidity();
            setIsLoggedIn(data.isValid);
        };
        validateToken();

        // Fetch the job details when the component mounts
        fetchJobDetails(job_id);
    }, [job_id]);

    // Fetch job details from the backend
    const fetchJobDetails = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/jobs/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
            });
            
            if (response.ok) {
                const data = await response.json();
                setJob(data);
                setIsLoading(false);
            } else {
                toast.error('Failed to fetch job details');
            }
        } catch (error) {
            console.error('Error fetching job details:', error);
            toast.error('Error fetching job details');
            setIsLoading(false);
        }
    };

    // If the job is still loading, display a loader
    if (isLoading) {
        return (
            <Segment>
                <Loader active inline="centered">Loading Job Details...</Loader>
            </Segment>
        );
    }

    return (
        <Segment>
            <Card fluid>
                {job ? (
                    <>
                        <Card.Content>
                            <Card.Header>{job.title}{job.viewers ? <Label basic style={{marginLeft: '2px', border: 'none'
                            }} ><Icon name="eye"/>{job.viewers.length}</Label> : null}
                            </Card.Header>
                                <Label color="teal" ribbon="right">
                                    Date Posted: {job.date ? formatDate(job.date) : 'Not available'}
                                </Label>
                            {job.company_id ? (
                                <Segment>
                                    <List>
                                        <List.Item>
                                            <strong>{job.company_id.name} </strong> 
                                        </List.Item>
                                        <List.Item>
                                            <i>{job.company_id.description}</i> 
                                        </List.Item>
                                        <List.Item>
                                            <Icon style={{marginRight: '5px'}} name={'group'} />{job.company_id.size}
                                        </List.Item>
                                    </List>
                                </Segment>
                            ) : 
                            <Card.Meta>{job.company}</Card.Meta>
                            }
                            <Card.Description>
                                <strong>Location:</strong> {job.place}
                            </Card.Description>
                        </Card.Content>

                        <Card.Content>
                            <Card.Description>
                                <strong>Job Type:</strong> {job.job_type}
                            </Card.Description>
                            <Card.Description>
                                <strong>Salary Range:</strong> {job.salary_range}
                            </Card.Description>
                            {job.associatedCourse && <LinkButton buttonText={'Prepare for this job'} color={'yellow'} iconName={'road'} link={`/projects/${job.associatedCourse}`}/>}
                            {job.associatedProject && <LinkButton buttonText={'Build for this job'} color={'orange'} iconName={'cog'} link={`/projects/${job.associatedProject}`}/>}
                        </Card.Content>

                        <Card.Content>
                            <strong>Key Responsibilities:</strong>
                            <List bulleted>
                                {job.key_responsibilities.map((responsibility, index) => (
                                    <List.Item key={index}>{responsibility}</List.Item>
                                ))}
                            </List>

                            <strong>Required Qualifications:</strong>
                            <List bulleted>
                                {job.required_qualifications.map((qualification, index) => (
                                    <List.Item key={index}>{qualification}</List.Item>
                                ))}
                            </List>

                            {job.bonus_points.length > 0 && (
                                <>
                                    <strong>Bonus Points:</strong>
                                    <List bulleted>
                                        {job.bonus_points.map((point, index) => (
                                            <List.Item key={index}>{point}</List.Item>
                                        ))}
                                    </List>
                                </>
                            )}
                        </Card.Content>
                        <Card.Content extra>
                            <Button primary onClick={() => window.open(job.link, '_blank')}>
                                <Icon name="external" /> Apply Now
                            </Button>
                        </Card.Content>
                    </>
                ) : (
                    <p>No job details found</p>
                )}
            </Card>
        </Segment>
    );
};

export default JobDetailsPage;
