import React, { useState, useEffect } from 'react';
import { Accordion, Icon, Segment, Label, Header, Button, Form, TextArea, Grid, Feed, FeedLike, Container, Divider, Message } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { mapMajorMinorToDisplay, mapTechnologyToDisplay } from '../displayHelpers';
import moment from 'moment';
import { analytics } from '../firebase-config';
import { logEvent } from "firebase/analytics";

const ReviewFeed = ({ reviews }) => {
  return (
    <Feed size={'small'}>
      {reviews?.map((review) => (
        <Feed.Event key={review._id}>
          <Feed.Label>
            <Icon size="large" name='user' />
          </Feed.Label>
          <Feed.Content>
            <Feed.Summary>
              <Feed.User>{review.userString}</Feed.User>
              <Feed.Date>{moment(review.createdAt).fromNow()}</Feed.Date>
            </Feed.Summary>
            <Feed.Extra text>{review.comment}</Feed.Extra>
          </Feed.Content>
        </Feed.Event>
      ))}
    </Feed>
  );
};

const ResumeForReview = ({ resume, reviewMode=true }) => {
  const [overallComment, setOverallComment] = useState('');
  const [contributionComments, setContributionComments] = useState({});
  const [overallThumbsUp, setOverallThumbsUp] = useState([]);
  const [contributionThumbsUp, setContributionThumbsUp] = useState({});
  const [overallCommentsFeed, setOverallCommentsFeed] = useState([]);
  const [contributionCommentsFeed, setContributionCommentsFeed] = useState({});
  const [activeIndex, setActiveIndex] = useState([]);

  useEffect(() => {
    fetchComments();
    fetchLikes();
  }, []);

  const isLiked = (resumeLikes) => {
    return resumeLikes?.some(like => like.user === localStorage.getItem('userId'))
  }

    // Fetch comments for the resume and contributions
    const fetchLikes = async () => {
      try {
        // Fetch resume comments
        const resumeLikesResponse = await fetch(`${process.env.REACT_APP_BACKEND}/api/resume/likes/resume/${resume._id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        });
  
        // Fetch contribution comments for each work experience
        const contributionLikesResponse = await Promise.all(
          resume.workContributions.map((contrib) =>
            fetch(`${process.env.REACT_APP_BACKEND}/api/resume/likes/contribution/${contrib._id}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
              },
            })
          )
        );
  
        const resumeLikesData = await resumeLikesResponse.json();
        const contributionLikesData = await Promise.all(contributionLikesResponse.map((res) => res.json()));
  
        setOverallThumbsUp(resumeLikesData.likes || []);
        setContributionThumbsUp(
          contributionLikesData.reduce((acc, data, idx) => {
            acc[resume.workContributions[idx]._id] = data.likes;
            return acc;
          }, {})
        );
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

  // Fetch comments for the resume and contributions
  const fetchComments = async () => {
    try {
      // Fetch resume comments
      const resumeCommentsResponse = await fetch(`${process.env.REACT_APP_BACKEND}/api/resume/comments/resume/${resume._id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      // Fetch contribution comments for each work experience
      const contributionCommentsResponse = await Promise.all(
        resume.workContributions.map((contrib) =>
          fetch(`${process.env.REACT_APP_BACKEND}/api/resume/comments/contribution/${contrib._id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
          })
        )
      );

      const resumeCommentsData = await resumeCommentsResponse.json();
      const contributionCommentsData = await Promise.all(contributionCommentsResponse.map((res) => res.json()));

      setOverallCommentsFeed(resumeCommentsData.reviews || []);
      setContributionCommentsFeed(
        contributionCommentsData.reduce((acc, data, idx) => {
          acc[resume.workContributions[idx]._id] = data.reviews;
          return acc;
        }, {})
      );
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  // Handle posting comments
  const handlePostComment = async (targetId, comment, onModel) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/resume/comment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({
          targetId,
          comment,
          onModel,
        }),
      });

      if (response.ok) {
        toast.success('Comment added successfully!');
        fetchComments(); // Refresh comments
      } else {
        const data = await response.json()
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error adding comment:', error);
      toast.error('Failed to add comment.');
    }
  };

  // Handle like/unlike
  const handleLikeToggle = async (targetId, onModel) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/resume/like`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({
          targetId,
          onModel,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        toast.success(data.message);
        fetchLikes(); // Refresh likes
      }
    } catch (error) {
      console.error('Error toggling like:', error);
      toast.error('Failed to toggle like.');
    }
  };

  const handleContributionThumbsUpChange = (contributionId) => {
    handleLikeToggle(contributionId, 'Contribution');
  };

  const handleOverallThumbsUpChange = () => {
    handleLikeToggle(resume._id, 'Resume');
  };

  const handleSubmitReview = () => {
    if (analytics) {
      logEvent(analytics, 'add_resume_review', {});
    } else {
        console.log('Analytics is not initialized');
    }
    handlePostComment(resume._id, overallComment, 'Resume');
  };

  const handleContributionCommentChange = (contributionId, value) => {
    setContributionComments({ ...contributionComments, [contributionId]: value });
  };

  const handleClick = (index) => {
    const newIndex = activeIndex.includes(index)
      ? activeIndex.filter((i) => i !== index)
      : [...activeIndex, index];
    setActiveIndex(newIndex);
  };

  const renderSkills = (skills) =>
    skills.map((skill) => (
      <Label key={skill} style={{ margin: '5px' }}>
        {mapTechnologyToDisplay(skill)}
      </Label>
    ));

  const formatDate = (dateString) => {
    if (!dateString || dateString.toLowerCase() === 'present') {
      return 'Present';
    }

    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  return (
    <Segment.Group>
      <Segment>
      {resume.workExperiences?.length > 0 && <Label style={{margin: '5px 0'}} color='teal'> <Icon name='building'/> {resume.workExperiences[0]?.jobTitle} <Label.Detail>{resume.workExperiences[0]?.company}</Label.Detail> </Label>}&nbsp;&nbsp;
      {resume.education?.length > 0 && <Label style={{margin: '5px 0'}} color='teal'> <Icon name='graduation'/> {resume.education[0]?.institution} <Label.Detail>{resume.education[0]?.degree}</Label.Detail> </Label>}<br/>
      {resume.overallSummary && <Message>{resume.overallSummary}</Message>}
      {reviewMode && (
          <Form>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column width={14}>
                  <Form.Field>
                    <TextArea
                      value={overallComment}
                      onChange={(e) => setOverallComment(e.target.value)}
                      placeholder='Add your overall comments here...'
                    />
                  </Form.Field>
                  <Button primary fluid onClick={handleSubmitReview}>
                    Add Review
                  </Button>
                </Grid.Column>
                <Grid.Column width={2}>
                <FeedLike
                  floated='right'
                  style={{ margin: '5px' }}
                  onClick={() => handleOverallThumbsUpChange(resume._id)}
                >
                  <Icon name={isLiked(overallThumbsUp) ? "star" : "star outline"} color={isLiked(overallThumbsUp) ? "yellow" : undefined}/>
                  {overallThumbsUp?.length}
                </FeedLike>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
      )}
        <ReviewFeed reviews={overallCommentsFeed} />
      </Segment>
      <Segment>
        <Accordion styled fluid>
          {/* Skills Section */}
          <Accordion.Title active={activeIndex.includes(0)} index={0} onClick={() => handleClick(0)}>
            <Icon name='dropdown' />
            Skills
          </Accordion.Title>
          <Accordion.Content active={activeIndex.includes(0)}>
            {resume.skills && resume.skills.length > 0 && <Segment>{renderSkills(resume.skills)}</Segment>}
          </Accordion.Content>

          {/* Education Section */}
          <Accordion.Title active={activeIndex.includes(1)} index={1} onClick={() => handleClick(1)}>
            <Icon name='dropdown' />
            Education
          </Accordion.Title>
          <Accordion.Content active={activeIndex.includes(1)}>
            {resume.education.map((edu) => (
              <Segment key={edu._id}>
                <Header as='h4'>{edu.institution}</Header>
                {edu.degree && <p>Degree: {edu.degree}</p>}
                {edu.major && edu.major.length > 0 && (
                  <p>Major: {edu.major.map(mapMajorMinorToDisplay).join(', ')}</p>
                )}
                {edu.graduationYear && <p>Graduation Year: {edu.graduationYear}</p>}
              </Segment>
            ))}
          </Accordion.Content>

          {/* Work Experiences Section */}
          <Accordion.Title active={activeIndex.includes(2)} index={2} onClick={() => handleClick(2)}>
            <Icon name='dropdown' />
            Work Experiences
          </Accordion.Title>
          <Accordion.Content active={activeIndex.includes(2)}>
            {resume.workExperiences.map((work) => (
              <Segment key={work._id}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <Label as='a' color='blue' ribbon>
                      <Icon name='briefcase' />
                      {work.jobTitle}
                    </Label>
                    <Label>
                      <Icon name='building outline' />
                      {work.company}
                    </Label>
                    {work.location && (
                      <Label>
                        <Icon name='map pin' />
                        {work.location}
                      </Label>
                    )}
                  </div>
                </div>
                <div style={{ marginTop: '10px' }}>
                  {work.endDate ? (
                    <Label>
                      {formatDate(work.startDate)}&nbsp;-&nbsp;{formatDate(work.endDate)}
                    </Label>
                  ) : (
                    <Label>
                      From: {formatDate(work.startDate)}
                      To: {formatDate(work.endDate)}
                    </Label>
                  )}
                </div>
                {resume.workContributions
                  .filter((contrib) => contrib.project === work._id)
                  .map((contrib) => (
                    <Container key={contrib._id}>
                      <Grid>
                        <Grid.Row columns={2}>
                          <Grid.Column width={14}>
                            {contrib.description && <p>{contrib.description}</p>}
                            {contrib.impact && <p>Impact: {contrib.impact}</p>}
                            {contrib.technologiesUsed && contrib.technologiesUsed.length > 0 && (
                              <p>Technologies Used: {renderSkills(contrib.technologiesUsed)}</p>
                            )}

                          </Grid.Column>
                          <Grid.Column width={2}>
                            <FeedLike
                              onClick={() => handleContributionThumbsUpChange(contrib._id)}
                            >
                              <Icon name={isLiked(contributionThumbsUp[contrib._id]) ? "heart" : "heart outline"} color={isLiked(contributionThumbsUp[contrib._id]) ? "red" : undefined}/>
                              {contributionThumbsUp[contrib._id]?.length}
                            </FeedLike>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                      <ReviewFeed reviews={contributionCommentsFeed[contrib._id]}/>
                      {reviewMode && (
                        <Form>
                          <Grid>
                            <Grid.Row columns={1}>
                              <Grid.Column width={16}>
                                <Form.Field>
                                  <TextArea
                                    value={contributionComments[contrib._id] || ''}
                                    onChange={(e) =>
                                      handleContributionCommentChange(contrib._id, e.target.value)
                                    }
                                    placeholder='Add your comments on this contribution...'
                                  />
                                </Form.Field>
                                <Button
                                  fluid
                                  primary
                                  onClick={() => handlePostComment(contrib._id, contributionComments[contrib._id], 'Contribution')}
                                >
                                  Add Review
                                </Button>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Form>
                      )}
                      <Divider />
                    </Container>
                  ))}
              </Segment>
            ))}
          </Accordion.Content>
        </Accordion>
      </Segment>
    </Segment.Group>
  );
};

export default ResumeForReview;
