import React, { useState, useCallback, useEffect } from 'react';
import { Button, Modal, Form, Segment, Dropdown, Icon, Accordion, Header, Popup, Input, Divider, Label } from 'semantic-ui-react';
import { generateSkillOptions, generateCourseOptions, generateDegreeOptions, generateMajorOptions, consolidatedOptions } from '../displayHelpers';
import Contributions from './Contributions';
import { toast } from 'react-toastify';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "../App.css"
import WorkExperienceEdit from './WorkExperienceEdit';
import PersonalProjectEdit from './PersonalProjectEdit';
import { addOption, getOptions } from '../optionsHelpers';
import AIResumeUploader from './AIResumeUploader';

const ResumeForm = ({editMode, resumeData, toggleEditMode, fetchResume}) => {    
  const [open, setOpen] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([])
  const [institutionOptions, setInstitutionOptions] = useState([])
  const [personalDetails, setPersonalDetails] = useState({
    fullName: '',
    githubProfile: '',
    linkedInProfile: ''
  });
  const [skills, setSkills] = useState([]);
  const [education, setEducation] = useState([{ institution: '', institution_id: '', degree: '', major: [], minor: [], relevantCourses: [], graduationYear: '' }]);
  const [workExperience, setWorkExperience] = useState([{ jobTitle: '', company: '', location: '', startDate: '', endDate: '', contributions: [{ description: '', impact: '', teamSize: '', category: '', technologiesUsed: [] }] }]);
  const [personalProjects, setPersonalProjects] = useState([{ projectTitle: '', contributions: [{ description: '', impact: '', teamSize: '', category: '', technologiesUsed: [] }], demoLink: '' }]);

  const fetchCompanyOptions = async () => {
    const companyOptions = await getOptions('company')
    setCompanyOptions(companyOptions)
  }
  
  const handleCompanyAddition = async (e, { value }) => {
    setCompanyOptions(prevOptions => [...prevOptions, { key: value, text: value, value }]);
    await addOption('company', value)
    await fetchCompanyOptions()
  };

  const [activeIndex, setActiveIndex] = useState(0); // For Accordion
  const handleClick = (index) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const resetForm = () => {
    setPersonalDetails({
        fullName: '',
        githubProfile: '',
        linkedInProfile: ''
    })
    setEducation([
        { institution: '', institution_id: '', degree: '', major: [], minor: [], relevantCourses: [], graduationYear: '' }
    ])
    setWorkExperience([
        { jobTitle: '', company: '', location: '', startDate: '', endDate: '', contributions: [{ description: '', impact: '', teamSize: '', category: '', technologiesUsed: [] }] }
    ])
    setPersonalProjects([
        { projectTitle: '', contributions: [{ description: '', impact: '', teamSize: '', category: '', technologiesUsed: [] }], demoLink: '' }
    ]) 
  }

  const fetchInstitutionOptions = async () => {
    const institutionOptions = await getOptions('institution')
    setInstitutionOptions(institutionOptions)
};

const handleInstitutionAddition = async (value) => {
  await addOption('institution', value);
  await fetchInstitutionOptions();
};

const handleInstitionChange = (index, institution) => {
    const selectedOption = institutionOptions.find(option => option.value === institution);
    if (selectedOption) {
      const newEducation = [...education];
      newEducation[index]['institution'] = institution;
      newEducation[index]['institution_id'] = selectedOption.key;
      setEducation(newEducation);
    }
};

  const handleCompanyChange = (index, company) => {
    // Check if options exist which typically means it's a dropdown
      const selectedOption = companyOptions.find(option => option.value === company);
      const newWorkExperience = [...workExperience];
      if (selectedOption) {
        newWorkExperience[index]['company_id'] = selectedOption.key;
        newWorkExperience[index]['company'] = selectedOption.value;
        setWorkExperience(newWorkExperience);
      }
  };

  // Education Handlers
  const handleAddEducation = () => {
    setEducation([...education, { institution: '', institution_id: '', degree: '', major: [], minor: [], relevantCourses: [], graduationYear: '', gpa: null }]);
  };

  const handleRemoveEducation = (index) => {
    setEducation(education.filter((_, eduIndex) => eduIndex !== index));
  };

  const handleEducationChange = (index, field, value) => {
    const newEducation = [...education];
    newEducation[index][field] = value;
    setEducation(newEducation);
  };

  // Work Experience Handlers
  const handleAddWorkExperience = () => {
    setWorkExperience([...workExperience, { jobTitle: '', location: '', startDate: '', endDate: '', contributions: [{ description: '', impact: '', teamSize: '', category: '', technologiesUsed: [] }] }]);
  };

  const handleRemoveWorkExperience = (index) => {
    setWorkExperience(workExperience.filter((_, expIndex) => expIndex !== index));
  };

  const handleWorkExperienceChange = (index, field, value) => {
    const newWorkExperience = [...workExperience];
    newWorkExperience[index][field] = value;
    setWorkExperience(newWorkExperience);
  };

  // Personal Projects Handlers
  const handleAddPersonalProject = () => {
    setPersonalProjects([...personalProjects, { projectTitle: '', contributions: [{ description: '', impact: '', teamSize: '', category: '', technologiesUsed: [] }], demoLink: '' }]);
  };

  const handleRemovePersonalProject = (index) => {
    setPersonalProjects(personalProjects.filter((_, projIndex) => projIndex !== index));
  };

  const handlePersonalProjectChange = (index, field, value) => {
    const newPersonalProjects = [...personalProjects];
    newPersonalProjects[index][field] = value;
    setPersonalProjects(newPersonalProjects);
  };

  const handleWorkExperienceContributionsChange = (experienceIndex, newContributions) => {
    // Create a new array with updated contributions for the specified work experience
    const updatedWorkExperiences = workExperience.map((experience, index) => {
      if (index === experienceIndex) {
        // Update the contributions for this experience
        return { ...experience, contributions: newContributions };
      }
      return experience;
    });
    setWorkExperience(updatedWorkExperiences);
  };

  const handlePersonalProjectContributionsChange = (projectIndex, newContributions) => {
        // Directly create a new array with the same personal projects
        const updatedPersonalProjects = [...personalProjects];

        // Update only the specified project's contributions
        if (updatedPersonalProjects[projectIndex]) {
            updatedPersonalProjects[projectIndex] = {
            ...updatedPersonalProjects[projectIndex],
            contributions: newContributions
            };
        }


    // Update the state with the modified list of personal projects
    setPersonalProjects(updatedPersonalProjects);
  };

  const handleSubmit = useCallback(async () => {
    // Construct the payload with the project details
    const payload = {
        personalDetails,
        skills,
        education,
        workExperience,
        personalProjects
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/resume`, { // Update with your actual endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`, // Assuming you're using JWT tokens for auth
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        resetForm()
        fetchResume()
        setOpen(false); // Close the modal
        toggleEditMode()
  
        // Display success message using toast
        toast.success('Resume details added successfully!');
      } else {
        const responseData = await response.json();
        toast.error(responseData.message || 'Failed to add resume details');
      }
    } catch (error) {
      console.error('Error submitting resume details:', error);
      toast.error('An error occurred while submitting resume details.');
    }
  }, [
    personalDetails,
        skills,
        education,
        workExperience,
        personalProjects
  ]);

  const handleUpdateSubmit = useCallback(async () => {
    // Construct the payload with the project details
    const payload = {
        personalDetails,
        skills,
        education,
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/resume/update/${resumeData._id}`, { // Update with your actual endpoint
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`, // Assuming you're using JWT tokens for auth
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        // Display success message using toast
        toast.success('Resume details updated successfully!');
      } else {
        const responseData = await response.json();
        toast.error(responseData.message || 'Failed to update resume details');
      }
    } catch (error) {
      console.error('Error submitting resume details:', error);
      toast.error('An error occurred while submitting resume details.');
    }
  }, [
    personalDetails,
        skills,
        education,
  ]);


    // Initialize the form with resume data in edit mode
    useEffect(() => {
        if (editMode && resumeData) {
            setPersonalDetails(resumeData.personalDetails || {});
            setSkills(resumeData.skills || []);
            setEducation(resumeData.education || []);
            setWorkExperience(resumeData.workExperiences || []);
            setPersonalProjects(resumeData.personalProjects || []);
            setOpen(true);
        }
    }, [editMode, resumeData]);

    useEffect(() => {
      fetchCompanyOptions()
      fetchInstitutionOptions()
    }, [])

    const handleSkillAddition = (e, { value }) => {
      // Add the new option to the options array
      setSkillsOptions(prevOptions => [...prevOptions, { key: value, text: value, value }]);
      addOption('skill', value)
    };
  
  
    const [skillOptions, setSkillsOptions] = useState(generateSkillOptions());

    const handleCourseAddition = (e, { value }) => {
      // Add the new option to the options array
      setCourseOptions(prevOptions => [...prevOptions, { key: value, text: value, value }]);
    };
  
  
    const [courseOptions, setCourseOptions] = useState(generateCourseOptions());

    const handleMajorAddition = (e, { value }) => {
      // Add the new option to the options array
      setMajorOptions(prevOptions => [...prevOptions, { key: value, text: value, value }]);
    };
  
  
    const [majorOptions, setMajorOptions] = useState(generateMajorOptions());

    const handleMinorAddition = (e, { value }) => {
      // Add the new option to the options array
      setMinorOptions(prevOptions => [...prevOptions, { key: value, text: value, value }]);
    };
  
  
    const [minorOptions, setMinorOptions] = useState(generateMajorOptions());

  return (
    <div>
      {!editMode && <AIResumeUploader setOpen={setOpen} />}
      {!editMode && <Label style={{marginTop: '5px'}} color='teal'>Resumes added are visible by default but you can change visibility at any time.</Label>}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Modal.Header>{editMode ? 'Update' : 'Add'} Resume Information</Modal.Header>
        <Modal.Content>
        <Form> 
        <Accordion fluid styled>
            <Accordion.Title active={activeIndex === 0} index={0} onClick={() => handleClick(0)}>
              <Icon name='dropdown' />
              Personal Details
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
                <Segment color='blue'>
                    <Form.Input
                    fluid label="Full Name" placeholder="Full Name"
                    value={personalDetails.fullName}
                    onChange={(e) => setPersonalDetails({ ...personalDetails, fullName: e.target.value })}
                    />
                        <Form.Group widths="equal">
                        <Form.Input
                        fluid label="GitHub Profile" placeholder="GitHub Profile URL"
                        value={personalDetails.githubProfile}
                        onChange={(e) => setPersonalDetails({ ...personalDetails, githubProfile: e.target.value })}
                        />
                        <Form.Input
                        fluid label="LinkedIn Profile" placeholder="LinkedIn Profile URL"
                        value={personalDetails.linkedInProfile}
                        onChange={(e) => setPersonalDetails({ ...personalDetails, linkedInProfile: e.target.value })}
                        />
                    </Form.Group>
                {editMode && <Button positive onClick={handleUpdateSubmit}>Update Personal Information</Button>}
                </Segment>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 1} index={1} onClick={() => handleClick(1)}>
              <Icon name='dropdown' />
              Skills
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
                <Segment color="blue">
                    <Form.Field>
                      <span>
                        <label>Skills</label>
                        <Popup
                          content='Skills should be listed in order of comfort-level and proficiency.'
                          trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
                        />
                      </span>
                      <Dropdown
                        label="Technologies Used"
                        placeholder="Skills"
                        fluid multiple selection search
                        options={consolidatedOptions(skillOptions, skills)}
                        onAddItem={handleSkillAddition}
                        value={skills}
                        allowAdditions={true}
                        onChange={(e, { value }) => setSkills(value)}
                      />
                    </Form.Field>
                {editMode && <Button positive onClick={handleUpdateSubmit}>Update Skills</Button>}
                </Segment>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 2} index={2} onClick={() => handleClick(2)}>
              <Icon name='dropdown' />
              Education
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
                <Segment color="blue">
                    {education.map((edu, index) => (
                        <Segment color="violet" key={index}>
                            <div className='container'>
                                <Header as="h4">Education #{index+1}</Header>
                                <Icon name="trash" size="large" className="icon-hover" onClick={() => handleRemoveEducation(index)}/>
                            </div>
                            <Form.Group widths="equal">
                              <Form.Dropdown
                                name="institution"
                                label="Institution"
                                placeholder="Institution"
                                fluid selection search
                                options={institutionOptions}
                                onAddItem={(e, {value}) => handleInstitutionAddition(value)}
                                value={edu.institution}
                                allowAdditions={true}
                                onChange={(e, { value }) => handleInstitionChange(index, value)}
                              />
                              <Form.Field>
                                <label>Degree</label>
                                <Dropdown
                                  placeholder="Degree"
                                  fluid
                                  selection
                                  search
                                  options={generateDegreeOptions()}
                                  value={edu.degree}
                                  onChange={(e, { value }) => handleEducationChange(index, 'degree', value)}
                                />
                              </Form.Field>

                              {/* Include other form fields here, wrapped in Form.Field */}
                            </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field>
                            <label>Major(s)</label>
                            <Dropdown
                              placeholder="Major(s)"
                              fluid multiple selection search
                              onAddItem={handleMajorAddition}
                              allowAdditions
                              options={majorOptions}
                              value={edu.major}
                              onChange={(e, { value }) => handleEducationChange(index, 'major', value)}
                            />
                          </Form.Field>
                            <Form.Field>
                              <label>Minor(s)</label>
                              <Dropdown
                                placeholder="Minor(s)"
                                fluid multiple selection search
                                onAddItem={handleMinorAddition}
                                allowAdditions
                                options={consolidatedOptions(minorOptions, edu.minor)}
                                value={edu.minor}
                                onChange={(e, { value }) => handleEducationChange(index, 'minor', value)}
                              />
                            </Form.Field>
                            <Form.Field>
                              <label>
                                Graduation Year
                                <Popup
                                  content="If currently enrolled, add the expected graduation date."
                                  trigger={<Icon name='info circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />}
                                />
                              </label>
                              <Input
                                type="text"
                                placeholder="Graduation Year"
                                value={edu.graduationYear}
                                onChange={(e) => handleEducationChange(index, 'graduationYear', e.target.value)}
                              />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>

                        </Form.Group>
                        <Form.Group widths='equal'>
                          <Form.Field>
                              <label>
                                  Relevant Courses
                                  <Popup
                                      content='Only list the courses obtained from this institution that are relevant to the type of jobs this resume will be used for.'
                                      trigger={<Icon name='info circle' style={{ marginLeft: '5px' }} />}
                                  />
                              </label>
                              <Form.Dropdown
                                  placeholder="Courses"
                                  fluid multiple selection search
                                  options={consolidatedOptions(courseOptions, edu.relevantCourses || [])}
                                  onAddItem={handleCourseAddition}
                                  value={edu.relevantCourses}
                                  allowAdditions={true}
                                  onChange={(e, data) => handleEducationChange(index, 'relevantCourses', data.value)}
                              />
                          </Form.Field>
                          <Form.Field>
                              <label>
                                  GPA
                                  <Popup
                                      content="Add your GPA on a 4.00 scale."
                                      trigger={<Icon name='info circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />}
                                  />
                              </label>
                              <Input
                                  type="number"
                                  placeholder="GPA"
                                  value={edu.gpa}
                                  onChange={(e) => handleEducationChange(index, 'gpa', e.target.value)}
                              />
                          </Form.Field>
                      </Form.Group>

                      </Segment>
                    ))}
                    {editMode && 
                    <>
                      <Button positive onClick={handleUpdateSubmit}>Update Education</Button>
                      <Divider/>
                    </>}
                    <Button type="button" onClick={handleAddEducation}>Add Another Education</Button>
                </Segment>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 3} index={3} onClick={() => handleClick(3)}>
              <Icon name='dropdown' />
              Work Experience
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 3}>
            <Segment color="blue">
              {editMode ? <WorkExperienceEdit resumeId={resumeData && resumeData._id} activeIndex={3}/> :
                workExperience.map((work, index) => (
                <Segment color="violet" key={index}>
                    <div className='container'>
                        <Header as="h4">Work Experience #{index+1}</Header>
                        <Icon name="trash" size="large" className="icon-hover" onClick={() => handleRemoveWorkExperience(index)}/>
                    </div>
                    <Form.Group widths="equal">  
                      <Form.Input
                          fluid label="Job Title" placeholder="Job Title"
                          value={work.jobTitle}
                          onChange={(e) => handleWorkExperienceChange(index, 'jobTitle', e.target.value)}
                      />
                      <Form.Dropdown
                        name="company"
                        label="Company"
                        placeholder="Company"
                        fluid selection search
                        options={companyOptions}
                        onAddItem={handleCompanyAddition}
                        value={work.company}
                        allowAdditions={true}
                        onChange={(e, { value }) => handleCompanyChange(index, value)}
                      />
                      <Form.Input
                          fluid label="Location" placeholder="Location"
                          value={work.location}
                          onChange={(e) => handleWorkExperienceChange(index, 'location', e.target.value)}
                      />
                      <Form.Checkbox
                          label="Current"
                          checked={work.current}
                          onChange={() => {
                              // Toggle the 'current' state and clear 'endDate' if 'current' is checked
                              const updatedExperience = [...workExperience];
                              updatedExperience[index].current = !updatedExperience[index].current;
                              if (updatedExperience[index].current) {
                              updatedExperience[index].endDate = null; // Set endDate to null if current job
                              }
                              setWorkExperience(updatedExperience);
                          }}
                          />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <div style={{marginLeft: 10}}>
                          <label htmlFor='startDatePicker'><strong>Start Date</strong></label>
                          <div>
                            <ReactDatePicker
                              selected={work.startDate}
                              id="startDatePicker"
                              onChange={(date) => handleWorkExperienceChange(index, 'startDate', date)}
                              dateFormat="MM/yyyy"
                              showMonthYearPicker
                              showFullMonthYearPicker
                            />
                          </div>
                        </div>

                        {/* Conditionally render the End Date input field */}
                        {!work.current && (
                        <div style={{marginLeft: 10}}>
                          <label htmlFor='endDatePicker'><strong>End Date</strong></label>
                          <div>
                            <ReactDatePicker
                              id="endDatePicker"
                              selected={work.endDate}
                              onChange={(date) => handleWorkExperienceChange(index, 'endDate', date)}
                              dateFormat="MM/yyyy"
                              showMonthYearPicker
                              showFullMonthYearPicker
                            />
                          </div>
                        </div>
                        )}
                    </Form.Group>
                    {[work.jobTitle, work.company, work.location, work.startDate, work.endDate].some(val => !!val) ? <Contributions 
                        type={'Work Experience'} parentIndex={index+1} onContributionsChange={(newContributions) => handleWorkExperienceContributionsChange(index, newContributions)}
                    /> : null}
                </Segment>
                ))}
                {editMode ? null : <Button type="button" onClick={handleAddWorkExperience}>Add Another Work Experience</Button>}
                </Segment>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 4} index={4} onClick={() => handleClick(4)}>
              <Icon name='dropdown' />
              Personal Projects
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 4}>
                <Segment color="blue">
                {editMode ? <PersonalProjectEdit resumeId={resumeData && resumeData._id} activeIndex={4}/> :
                    personalProjects.map((project, index) => (
                    <Segment color="violet" key={index}>
                        <div className='container'>
                            <Header as="h4">Personal Project #{index+1}</Header>
                            <Icon name="trash" size="large" className="icon-hover" onClick={() => handleRemovePersonalProject(index)}/>
                        </div>
                        <Form.Input
                            fluid label="Project Title" placeholder="Project Title"
                            value={project.projectTitle}
                            onChange={(e) => handlePersonalProjectChange(index, 'projectTitle', e.target.value)}
                        />
                        {!!project.projectTitle ? <Contributions 
                            type={'Personal Project'} parentIndex={index+1} onContributionsChange={(newContributions) => {handlePersonalProjectContributionsChange(index, newContributions)}} 
                        /> : null}
                    </Segment>
                    ))}
                    {editMode ? null : <Button type="button" onClick={handleAddPersonalProject}>Add Another Personal Project</Button>}
                </Segment>
            </Accordion.Content>            
            </Accordion>
            </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => {
            if (editMode) {
              toggleEditMode()
            }
            setOpen(false)
            if (editMode) {
              fetchResume()
            }
            }}>{editMode ? 'Done Editing' : 'Cancel'}</Button>
          {!editMode && <Button positive onClick={handleSubmit}>Save</Button>}
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default ResumeForm;
