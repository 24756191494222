import React, { useState, useEffect } from 'react';
import { Modal, Card, Label, Icon, Button, Popup } from 'semantic-ui-react';
import "../App.css"
import { toast } from 'react-toastify';
import { analytics } from '../firebase-config';
import { logEvent } from "firebase/analytics";
import LongListLabel from './LongListLabel';

const JobsModal = ({ companyId, isModalOpen, closeModal, isLoggedIn }) => {
  const [jobs, setJobs] = useState([]);
  const [selectedJobDescription, setSelectedJobDescription] = useState("");
  const [modalOpen, setModalOpen] = useState(false);


  const openJobLink = (url) => {
    window.open(url, '_blank');
  };

  const addToMyJobs = async(jobId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/jobs/applications/add/${jobId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
        });
        if (response.ok) {
            toast.success('Added job to applications!');
        } else if (response.status === 409) {
            const data = await response.json();
            toast.warn(data.message || "You have already added this application");
        } 
        else {
            toast.error('Failed to add to applications!');
        }
    } catch (error) {
        console.error('Failed to add to applications:', error);
        toast.error('Failed to add to applications!');
    }
}

  useEffect(() => {
    if (companyId) {
      fetchJobs();
    }
  }, [companyId]);

  const fetchJobs = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/jobs/byCompany?companyId=${companyId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          }
        }
      );
      const data = await response.json();
      setJobs(data.jobs);
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const getRatingLabelColor = (rating) => {
    switch (rating) {
        case 'poor':
            return 'red'
        case 'average':
            return 'orange'
        case 'good':
            return 'yellow'
        case 'excellent':
            return 'green'
        default:
            return 'grey'
    }   
}

const getRatingIconName = (rating) => {
    switch (rating) {
        case 'poor':
            return 'frown outline'
        case 'average':
            return 'frown outline'
        case 'good':
            return 'smile outline'
        case 'excellent':
            return 'star outline'
        default:
            return 'question circle outline'
    }   
}

  const capitalizeFirstLetter = (string) => {
    if (!string) return string; // return original string if it's empty or undefined
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const toggleModal = (description) => {
    setSelectedJobDescription(description); // Set the description to state
    setModalOpen(!modalOpen); // Toggle the state of the modal visibility
  };

  return (
    <Modal open={isModalOpen} onClose={closeModal}>
      <Modal.Header>Job Listings</Modal.Header>
      <Modal.Content>
      {jobs.map((job, index) => (
        <Card key={index}>
            <Card.Content>
                <Card.Header>{job.title}</Card.Header>
                <Card.Meta>{job.company}</Card.Meta>
                <Card.Description>
                    <strong>Location:</strong> {job.place}
                </Card.Description>
                <Label color='teal' ribbon='right'>
                    Date Posted: {job.date ? formatDate(job.date) : 'Not available'}
                </Label>
                {job.hand_picked && <Label color='orange' ribbon='right'>
                    Hand Picked
                </Label>}
            </Card.Content>
                {
                [job.career_growth_categorization, job.compensation_and_benefits_categorization, job.company_culture_categorization, job.management_categorization, job.work_life_balance_categorization].some(element => element !== null && element !== "") ?
                <Card.Content extra>
                    {job.career_growth_categorization ? 
                    <Label size="tiny" style={{margin: 2}} basic color={getRatingLabelColor(job.career_growth_categorization)}>
                        <Icon name={getRatingIconName(job.career_growth_categorization)} /> Career Growth: {capitalizeFirstLetter(job.career_growth_categorization)}
                    </Label>
                    : null}
                    {job.compensation_and_benefits_categorization ? 
                    <Label size="tiny" style={{margin: 2}} basic color={getRatingLabelColor(job.compensation_and_benefits_categorization)}>
                        <Icon name={getRatingIconName(job.compensation_and_benefits_categorization)} /> Pay/Benefits: {capitalizeFirstLetter(job.compensation_and_benefits_categorization)}
                    </Label> 
                    : null}
                    {job.company_culture_categorization ? 
                    <Label size="tiny" style={{margin: 2}} basic color={getRatingLabelColor(job.company_culture_categorization)}>
                        <Icon name={getRatingIconName(job.company_culture_categorization)} /> Culture: {capitalizeFirstLetter(job.company_culture_categorization)}
                    </Label>  
                    : null}
                    {job.management_categorization ? 
                    <Label size="tiny" style={{margin: 2}} basic color={getRatingLabelColor(job.management_categorization)}>
                        <Icon name={getRatingIconName(job.management_categorization)} /> Management: {capitalizeFirstLetter(job.management_categorization)}
                    </Label>   
                    : null}
                    {job.work_life_balance_categorization ? 
                    <Label size="tiny" style={{margin: 2}} basic color={getRatingLabelColor(job.work_life_balance_categorization)}>
                        <Icon name={getRatingIconName(job.work_life_balance_categorization)} /> Work Life Balance: {capitalizeFirstLetter(job.work_life_balance_categorization)}
                    </Label> 
                    : null}
                </Card.Content>
                : null
                }
                {
                    job.skills ? 
                    <Card.Content extra>
                        <LongListLabel labels={job.skills} color={"blue"}/>
                    </Card.Content> : null
                }
                <Card.Content extra>
                <Card.Content extra>
                    <Button primary onClick={() => openJobLink(job.link)}>Open Job</Button>
                    {isLoggedIn && <Button primary onClick={() => {
                        if (analytics) {
                            logEvent(analytics, 'add_to_my_jobs', {
                                content_type: 'job',
                                content_id: job._id,
                                items: [{ name: 'added_job', job_id: job._id  }]
                            });
                        } else {
                            console.log('Analytics is not initialized');
                        }
                        addToMyJobs(job._id)
                    }}>Add To My Jobs</Button>}
                </Card.Content>
            </Card.Content>
        </Card>
    ))}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)} dimmer="blurring">
          <Modal.Header>Job Description</Modal.Header>
          <Modal.Content>
              <p style={{ whiteSpace: 'pre-wrap' }}>{selectedJobDescription}</p>
          </Modal.Content>
          <Modal.Actions>
              <Button color='green' onClick={() => setModalOpen(false)}>
                  Close
              </Button>
          </Modal.Actions>
        </Modal>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default JobsModal;
