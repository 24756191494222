import React from 'react';
import { Label, Icon, Popup, Header } from 'semantic-ui-react';

const CoursePills = ({ courses }) => {
  return (
    <div>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
        {courses.map((course) => (
          <Popup
            key={course._id}
            content={
              <div>
                <strong>{course.projectTitle}</strong>
                <br />
                {course.topics.join(', ')}
              </div>
            }
            trigger={
              <Label
                as="a"
                href={`/projects/${course._id}`}
                target="_blank"
                rel="noopener noreferrer"
                color="orange"
                circular
                style={{ textDecoration: 'none', cursor: 'pointer' }}
              >
                <Icon name="graduation cap" />
                {course.projectTitle}
              </Label>
            }
          />
        ))}
      </div>
    </div>
  );
};

export default CoursePills;
