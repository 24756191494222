import React, { useEffect, useState } from 'react';
import { Form, Button, Segment, Divider, Container, Label, Header, TextArea, Popup, Icon, Dropdown, Modal, Checkbox, List, Grid, Message } from 'semantic-ui-react';
import ReactDatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { createLabel, projectTimelineOptions } from '../displayHelpers';
import AddNewTaskForExistingTimelineForm from './AddNewTaskForExistingTimelineForm';
import TaskItem from './TaskItem';
import TimelineMutableTasksList from './TimelineMutableTasksList';

function addDaysToDate(date, daysToAdd) {
    let result = new Date(date);  // Create a new Date object to avoid mutating the original date
    result.setDate(result.getDate() + daysToAdd);  // Add the days
    return result;
}

const mapEndDateToDays = (timeline) => {
    const timelineToDays = {
        '1w': 7,
        '2w': 7 * 2,
        '3w': 7 * 3,
        '4w': 7 * 4,
        '6w': 7 * 6,
        '6w+': 7 * 52,
    }
    return timelineToDays[timeline] || 7 * 4; // default to 4 week window if timeline not given
}

const isCreator = (creatorId) => {
    return localStorage.getItem('userId') === creatorId;
};

const PlayNotebookLLmFile = ({ notebookLink }) => {
    return (
        <Button basic color="purple" onClick={() => {window.open(notebookLink, '_blank', 'noopener,noreferrer');}}>
            <Icon name="play" style={{marginRight: '2px'}}/>
            Play Course (Powered by NotebookLLM)
        </Button>
    )
}

const AddAudioLink = ({projectId}) => {
    const [notebookLlmLink, setNotebookLlmLink] = useState('')
    const handleSubmit = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/update/${projectId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
                body: JSON.stringify({
                    notebookLlmAudioLink: notebookLlmLink
                })
            });
            if (response.ok) {
                toast.success('Project updated successfully!');
            } else {
                throw new Error('Failed to update project');
            }
        } catch (error) {
            console.error('Error updating project:', error);
            toast.error('Failed to update project');
        }
    };

    return (
        <Form>
            <Form.Group centered style={{margin: '5px'}}>
                <Form.Input
                    name="audioLink"
                    placeholder="Notebook LLM link"
                    value={notebookLlmLink}
                    onChange={(e) => setNotebookLlmLink(e.target.value)}
                />
                <Button primary onClick={handleSubmit} style={{ marginLeft: '10px' }}>
                    Update Audio Link for Course
                </Button>
            </Form.Group>
        </Form>
    )
}

const AddAudioLinkOrPlayer = ({ project }) => {
    if (project.course_generation_status === 'completed') {
        if (project.notebookLlmAudioLink) {
            return <>
                <PlayNotebookLLmFile notebookLink={project.notebookLlmAudioLink} title={project.projectTitle}/>
                <AddAudioLink projectId={project._id}/>
            </>
        }
        return <AddAudioLink projectId={project._id}/>
    }
    return null
}

const CourseBuilder = ({ projectId, toggleModal, fetchProjects, isAdmin=false }) => {
    const [startDate, setStartDate] = useState(new Date());
    const [startDateModalOpen, setStartDateModalOpen] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [project, setProject] = useState({
        background: '',
        features: [],
        timeline: ''
    });
    const [loading, setLoading] = useState(false);
    const [taskForm, setTaskForm] = useState({ content: '', description: '', duration: '', dependencies: [], startDate: new Date().toISOString().split('T')[0] });

    const fetchProject = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/get_one/${projectId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
            });
            if (response.ok) {
                const responseData = await response.json();
                setProject(responseData);
                setLoading(false);
            } else {
                setLoading(false);
                console.error('Failed to fetch project');
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching project:', error);
        }
    };

    useEffect(() => {
        fetchProject();
    }, []);

    const fetchProjectDetails = async () => {
        if (!project.project_timeline) {
            setTaskForm(prev => ({ ...prev, startDate: new Date().toISOString().split('T')[0] }));
            setTasks([]);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/project_timeline/${project.project_timeline}`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('authToken')}` }
            });
            if (!response.ok) throw new Error('Failed to fetch project details');
            
            const project_timeline = await response.json();
            setTaskForm(prev => ({ ...prev, startDate: project_timeline.startDate.split('T')[0] }));

            setTasks(project_timeline.tasks.map(task => ({
                ...task,
                id: task._id,
                start: new Date(task.start),
                end: new Date(task.end),
                taskStatus: task.taskStatus,
                group: 1
            })));

            setStartDate(new Date(project_timeline.startDate));
        } catch (error) {
            console.error('Error fetching project details:', error);
        }
    };

    useEffect(() => {
        fetchProjectDetails();
    }, [project.project_timeline, project._id]);  // Dependencies related only to fetching data
    
    const handleInputChange = (e, { name, value }) => {
        if (name === 'features') {
            // Assuming features can be added as an array
            setProject(prev => ({ ...prev, features: [...prev.features, value] }));
        } else {
            setProject(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/update/${projectId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
                body: JSON.stringify(project)
            });
            if (response.ok) {
                setLoading(false);
                toast.success('Project updated successfully!');
            } else {
                throw new Error('Failed to update project');
            }
        } catch (error) {
            setLoading(false);
            console.error('Error updating project:', error);
            toast.error('Failed to update project');
        }
    };

    const generateCourseWithAI = async (beginner = false) => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/generate_course/${projectId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
                body: JSON.stringify({ beginner })
            });
            if (response.ok) {
                setLoading(false);
                toast.success('Course generation in progress... Please check back later.');
            } else {
                throw new Error('Failed to start course generation for project');
            }
        } catch (error) {
            setLoading(false);
            console.error('Failed to start course generation for project:', error);
            toast.error('Failed to start course generation for project');
        }
    };

    const handleAddTask = () => {
        if (!taskForm.content || !taskForm.duration) return;
    
        // Compute the earliest start date based on dependencies
        const maxEndDate = taskForm.dependencies.reduce((acc, curr) => {
            const dependentTask = tasks.find(task => task.id === curr);
            return new Date(dependentTask.end) > new Date(acc) ? dependentTask.end : acc;
        }, taskForm.startDate);
    
        const startDate = new Date(maxEndDate);
        startDate.setSeconds(startDate.getSeconds() + 1); // Add a second to start the task
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + parseInt(taskForm.duration));
        const newTask = {
            id: tasks.length + 1,
            content: taskForm.content,
            description: taskForm.description,
            start: startDate.toISOString().split('T')[0],
            end: endDate.toISOString().split('T')[0],
            group: 1
        };
    
        setTasks(prevTasks => [...prevTasks, newTask]);
        setTaskForm(prev => ({ ...prev, content: '', description: '', duration: '', dependencies: [] }));
    };    

    const handleChange = (e, { name, value, checked }) => {
        if (name === 'dependencies') {
            setTaskForm(prev => ({
                ...prev,
                dependencies: checked
                    ? [...prev.dependencies, parseInt(value)]
                    : prev.dependencies.filter(dep => dep !== parseInt(value))
            }));
        } else {
            setTaskForm(prev => ({ ...prev, [name]: value }));
        }
    };

    const submitTimeline = async () => {
        setLoading(true);
        const projectDetails = {
            startDate: taskForm.startDate,
            endDate: project.timeline ? addDaysToDate(taskForm.startDate, mapEndDateToDays(project.timeline)) : null,
            tasks: tasks.map(task => ({
                content: task.content,
                description: task.description,
                start: task.start,
                end: task.end,
                dependencies: task.dependencies
            }))
        };
    
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/project/${project._id}/timeline`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
                body: JSON.stringify(projectDetails)
            });
    
            if (!response.ok) {
                setLoading(false);
                toast.error("Failed to create project timeline");
                return;
            }
    
            const result = await response.json();
            toast.success("Created project timeline successfully!");
            await fetchProjectDetails();
            setLoading(false);
            fetchProjects();
            toggleModal(project._id); // to allow the Timeline to be refresh on reopen
        } catch (error) {
            setLoading(false);
            toast.error("Failed to create project timeline");
            console.error('Error creating project:', error);
        }
    };

    const handleStartDateModalToggle = () => {
        setStartDateModalOpen(!startDateModalOpen);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleStartDateUpdateSubmit = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/updateStartDate/${projectId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({ startDate: startDate.toISOString() })
            });

            if (!response.ok) throw new Error('Failed to update start date');
            toast.success('Start date updated successfully');
            handleStartDateModalToggle();
            fetchProjectDetails();
        } catch (error) {
            console.error('Error updating start date:', error);
            toast.error('Error updating start date');
        }
    };

    const publishCourse = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/${projectId}/publish`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            });

            if (!response.ok) throw new Error('Failed to update published status for course');
            toast.success('Course Published status updated!');
        } catch (error) {
            console.error('Error updating course published status', error);
            toast.error('Error updating course published status');
        }
    };

    const downloadTextContent = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/${projectId}/download-pdf`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            });
    
            if (!response.ok) {
                throw new Error('Failed to download PDF');
            }
    
            // Convert the response into a Blob
            const blob = await response.blob();
    
            // Create a link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `project-${projectId}.pdf`;  // Set the default file name for the download
    
            // Append the link to the body and trigger a click
            document.body.appendChild(link);
            link.click();
    
            // Clean up by removing the link element
            document.body.removeChild(link);
            
            toast.success('PDF downloaded successfully');
        } catch (error) {
            console.error('Error downloading course text', error);
            toast.error('Error downloading course text');
        }
    };    

    const isCreatorOrParticipant = () => {
        return (project.project_creator === localStorage.getItem('userId')) ||
        (project.participants.some(p => p.user._id === localStorage.getItem('userId')));
    };

    return (
        <Segment.Group>
            <Segment loading={loading}>
                <Form>
                    <Form.Field>
                        <label>Title</label>
                        <Form.Input
                            type="text"
                            name="projectTitle"
                            placeholder="Project Title"
                            value={project.projectTitle}
                            onChange={handleInputChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Background</label>
                        <TextArea
                            placeholder='Project Background'
                            name="background"
                            value={project.background}
                            onChange={handleInputChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Timeline</label>
                        <Dropdown
                            placeholder='Select Timeline'
                            fluid
                            selection
                            options={projectTimelineOptions()}
                            value={project.timeline}
                            onChange={(e, { value }) => handleInputChange(e, { name: 'timeline', value })}
                        />
                    </Form.Field>
                    <Button onClick={handleSubmit} primary>Update Project</Button>
                </Form>
            </Segment>
            <Segment>
                {!project.project_timeline ?
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '2px' }}>
                        <Form.Input
                            type="date"
                            name="startDate"
                            label="Start Date"
                            value={taskForm.startDate}
                            onChange={handleChange}
                            style={{ flex: 1 }} // Optionally make the input flex to take necessary space
                        />
                    </div>
                    :
                    <>
                    <Label
                        style={{'margin': '2px'}}
                        color='olive'
                        size="large"
                    >
                        Start Date: {taskForm.startDate || 'Unavailable'}
                        <Icon name="edit" onClick={handleStartDateModalToggle} style={{ cursor: 'pointer', marginLeft: '10px' }} />
                    </Label>
                    <Popup
                        style={{marginLeft: 'auto'}}
                        content={
                        <div>
                            <p>Update the start date as needed. The start and end dates of tasks will update automatically.</p>  
                        </div>
                        }
                        trigger={<Icon name='info circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />}
                    />
                    <Modal open={startDateModalOpen} onClose={handleStartDateModalToggle} size="small">
                        <Modal.Header>Update Start Date</Modal.Header>
                        <Modal.Content>
                            <ReactDatePicker
                              selected={startDate}
                              id="startDatePicker"
                              onChange={handleStartDateChange}
                              dateFormat="yyyy-MM-dd"
                            />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={handleStartDateModalToggle}>Cancel</Button>
                            <Button positive onClick={handleStartDateUpdateSubmit}>Save Changes</Button>
                        </Modal.Actions>
                    </Modal>
                    </>
                }
            </Segment>
            {!project.project_timeline ?
            <Segment loading={loading}>
                <Form>
                    <Header as='h5'>
                        Add Task(s)
                        <Popup
                            content={
                            <div>
                                <p>Tasks added will show up on the Timeline below. Make sure to Submit Timeline for project when done adding all tasks.</p>  
                            </div>
                            }
                            trigger={<Icon name='info circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />}
                        />
                    </Header>
                    <Form.Input
                        type="text"
                        name="content"
                        placeholder="Task name"
                        value={taskForm.content}
                        onChange={handleChange}
                    />
                    <Form.Input
                        type="text"
                        name="description"
                        placeholder="Task description (optional)"
                        value={taskForm.description}
                        onChange={handleChange}
                    />
                    <Form.Input
                        type="number"
                        name="duration"
                        placeholder="Duration (days)"
                        value={taskForm.duration}
                        onChange={handleChange}
                    />
                    <Container>
                        <>
                        {tasks.length > 0 ? <p><strong>Select the task(s) this new task is dependent on. Leave all tasks unchecked if this task can be completed independently.</strong></p> : null}
                        {tasks.map(task => (
                            <Form.Field 
                                key={task.id}
                                style={{'margin': '2px'}}
                            >
                                <Checkbox
                                    label={task.content}
                                    name="dependencies"
                                    value={task.id}
                                    checked={taskForm.dependencies.includes(task.id)}
                                    onChange={handleChange}
                                />
                                <Label>
                                    {task.description}
                                </Label>
                            </Form.Field>
                        ))}
                        </>
                    </Container>
                    <Button disabled={!taskForm.content || !taskForm.duration} onClick={handleAddTask}>Add Task</Button>
                    </Form>
                    <Divider />
                    <Button disabled={!tasks.length} onClick={submitTimeline} color="green">Submit timeline for project</Button>
                </Segment>
                :
                <Segment>
                    <Header>Tasks for timeline:</Header>
                    {tasks.length > 0 && <TimelineMutableTasksList
                        tasks={tasks}
                        timelineId={project.project_timeline}
                        showDetails={false}
                        fetchProjectDetails={fetchProjectDetails}
                    />}
                    <AddNewTaskForExistingTimelineForm forCourseBuilder projectTimelineId={project.project_timeline} refetchProjectDetails={fetchProjectDetails}/>
                    <List>
                        {tasks.map(task => (
                            <TaskItem
                                key={task._id}
                                task={task}
                                isCreatorOrParticipant={isCreatorOrParticipant}
                                isCreator={isCreator(project.project_creator) || isAdmin}
                                refetchProjectDetails={fetchProjectDetails}
                                forCourseBuilder
                                courseGenerationComplete={project.course_generation_status === 'completed'}
                            />
                        ))}
                    </List>
                </Segment>
            }
            {isCreator(project.project_creator) ? <Segment>
                <Grid centered>
                    <Grid.Row>
                        <Grid.Column textAlign="center">
                            <>
                                {project.course_generation_status === 'pending' && (
                                    <Button.Group>
                                        <Button icon labelPosition='left' color="purple" onClick={() => generateCourseWithAI(false)}>
                                            <Icon name='magic' />
                                            Generate Course with AI
                                        </Button>
                                        <Button icon labelPosition='left' color="blue" onClick={() => generateCourseWithAI(true)}>
                                            <Icon name='magic' />
                                            Generate Beginner Course with AI
                                        </Button>
                                    </Button.Group>
                                )}
                                {project.course_generation_status === 'failed' && (
                                    <>
                                        <Message size={'small'}>
                                            <Message.Content>
                                                There was an error generating your course. Please retry generating your course. There is likely a process failure and there is no issue with your course material.
                                            </Message.Content>
                                        </Message>
                                        <Button icon labelPosition='left' color="purple" onClick={() => generateCourseWithAI(false)}>
                                            <Icon name='magic' />
                                            Generate Course with AI
                                        </Button>
                                    </>
                                )}
                                {project.course_generation_status === 'in_progress' && createLabel('purple', 'Course Generation in progress...', 'hourglass outline')}
                                {project.course_generation_status === 'completed' && createLabel('purple', 'Course Generation Complete!', 'check circle')}
                                {project.course_generation_status === 'completed' && 
                                <Button style={{marginTop: '5px'}} primary onClick={publishCourse}>{project.published ? `Unpublish Course` : `Publish Course`}</Button>
                                }
                                {project.course_generation_status === 'completed' && <Button style={{marginTop: '5px'}} primary onClick={downloadTextContent}>Download Course Content</Button>}
                                <AddAudioLinkOrPlayer project={project}/>
                            </>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment> : null}
        </Segment.Group>
    );
};

export default CourseBuilder;
