import React from 'react';
import { Accordion, Segment, Icon } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';

const CollapsibleSegment = ({ title, content }) => {
    const [activeIndex, setActiveIndex] = React.useState(-1);

    const handleClick = () => {
        setActiveIndex(activeIndex === 0 ? -1 : 0);
    };

    return (
        <Accordion styled style={{ margin: '20px 0', width: '100%' }}>
            <Accordion.Title active={activeIndex === 0} onClick={handleClick}>
                <Icon name={activeIndex === 0 ? 'caret down' : 'caret right'} />
                <i>{title}</i>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
                <Segment>
                    <ReactMarkdown>{content}</ReactMarkdown>
                </Segment>
            </Accordion.Content>
        </Accordion>
    );
};

const ExerciseComponent = ({ currentTask }) => {
    const combinedContent = [
        currentTask?.additionalContext,
        currentTask?.courseTask?.additional_context_material
    ].filter(Boolean).join('\n\n'); // Join with two newlines for better formatting

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '100%' }}>
            {combinedContent && (
                <CollapsibleSegment
                    title="Exercise or Demonstration for Learning"
                    content={combinedContent}
                />
            )}
        </div>
    );
};

export default ExerciseComponent;
